import * as React from "react";

import { ITopicItemProps} from "./~types/TopicItemProps";
import { ITopicItemState } from "./~types/TopicItemState";


import {Button, Input} from "reactstrap";

import "./TopicItem.css";
import SettingsItem from "src/app/setting/settings-main/settings-list/setting-item/SettingItem";
import i18n from "src/app/shared/localization/i18n";
import { ISetting } from "src/app/setting/~store/models/setting";

export default class TopicItem extends React.Component<ITopicItemProps, ITopicItemState> {
    public readonly state: Readonly<ITopicItemState> = {
        topic: this.props.topic,
        isEditing: !this.props.topic.topicId,
        innerTopicName: this.props.topic.name
    };

    constructor(props: ITopicItemProps) {
        super(props);
    }

    public render() {
        const {isEditing} = this.state;
        const shouldBeEdit = isEditing || this._checkIsNewTopic();

        return (
            <div className="topic-item">
                <div className="topic-item__content topic-item__content-grow" onClick={this._onSelectTopicHandler}>
                    {shouldBeEdit ? this._getEditControl() : this._getDisplayControl()}
                </div>
                <div className="topic-item__content">{shouldBeEdit ? this._getEditButtons() : this._getDisplayButtons()}</div>                
            </div>
        );
    }

    private _onSelectTopicHandler = (): void => {
        const {isEditing} = this.state;
        if (!isEditing) {
            this.props.onSelectTopic(this.props.topic.topicExternalId);
        }
    };

    private _isTopicNameFilled = (): boolean => {
        return this.state.innerTopicName.trim().length === 0;
    };


    private _onToggleEditHandler = (): void => {
        this._changeEditingState();
    };

    private _onSaveTopicHandler = (): void => {
        const { innerTopicName, topic} = this.state;
        const { onSaveTopic } = this.props;

        onSaveTopic(topic.topicExternalId, innerTopicName, topic.parametersJson);
        this._discardState();
    };

    private _onDeleteHandler = (): void => {
        const {topicExternalId} = this.props.topic;
        this.props.onDeleteTopic(topicExternalId);
    };

    private _onChangeInnerNameHandler = (event: React.FormEvent<HTMLInputElement>): void => {
        this._changeInnerTopicName(event.currentTarget.value);
    };

    private _onDiscardChangeHandler = (): void => {
        this._discardState();
    };

    private _onGetScript = (): void => {
        const topicExternalId: string = this.props.topic.topicExternalId;
        const url: string = window.location.protocol + "//" + window.location.host;
        this.props.onGetScript(topicExternalId, url);
    };

    private _discardState = (): void => {
        const {name} = this.props.topic;
        const {isEditing} = this.state;
        this.setState({innerTopicName: name, isEditing: !isEditing});
    };

    private _changeEditingState = (): void => {
        const {isEditing} = this.state;
        this.setState({isEditing: !isEditing});
    };

    private _changeInnerTopicName = (newInnerTopicName: string) => {
        this.setState({innerTopicName: newInnerTopicName});
    };

    private _checkIsNewTopic = (): boolean => {
        return this.props.topic.topicId === 0;
    };

    private _getDisplayControl = (): React.ReactElement<{}> => {
        const {topic} = this.props;

        return (
            <React.Fragment>
                <span className="topic-item__identifier">{`#${topic.topicId}`}</span>
                <span className="topic-item__name">{topic.name}</span>
            </React.Fragment>
        );
    };

    private _getEditControl = (): React.ReactElement<{}> => {
        const {innerTopicName} = this.state;

        return (
            <React.Fragment>
                <Input value={innerTopicName} onChange={this._onChangeInnerNameHandler} autoFocus={true} />
            </React.Fragment>
        );
    };

    private _getDisplayButtons = (): React.ReactElement<{}> => {
        return (
            <React.Fragment>
                {this.props.canUserManageTopic && <Button className="topic-item__button" onClick={this._onGetScript}>
                    {i18n.t('nlp.getScript')}
                </Button>}
                {this.props.canUserSearchTopic && <Button className="topic-item__button" onClick={() => this.props.onSearchHandler(this.props.topic.name)}>
                    <i className="material-icons">search</i>
                </Button>}
                {this.props.canUserManageTopic && (
                    <SettingsItem
                        setting= { {value: this.props.topic.parametersJson} as ISetting }
                        onSettingSave={(setting) => this._onSettingsSaveButtonClick(setting.value)}
                    />
                )}
                {this.props.canUserManageTopic && <Button className="topic-item__button topic-item__button-edit" onClick={this._onToggleEditHandler}>
                    <i className="material-icons">edit</i>
                </Button>}
                {this.props.canUserManageTopic && <Button className="topic-item__button topic-item__button-delete" onClick={this._onDeleteHandler}>
                    <i className="material-icons">delete_forever</i>
                </Button>}
            </React.Fragment>
        );
    };

    private _getEditButtons = (): React.ReactElement<{}> => {
        return (
            <React.Fragment>
                <Button className="topic-item__button topic-item__button-save"
                 onClick={this._onSaveTopicHandler}
                 disabled={this._isTopicNameFilled()}>
                    <i className="material-icons">save</i>
                </Button>
                {!this._checkIsNewTopic() && (
                    <Button className="topic-item__button topic-item__button-discard" onClick={this._onDiscardChangeHandler}>
                        <i className="material-icons">replay</i>
                    </Button>
                )}
            </React.Fragment>
        );
    };

    private _onSettingsSaveButtonClick = (settings: string) => {
        const { topic } = this.state;

        this.setState({ topic: { ...topic, parametersJson: settings } },
                () => this._onSaveTopicHandler());
    }
}
