import {createSelector} from "reselect";

import {ITopic} from "./models/Topic";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { SortingOption } from "src/app/nlp/~store/models/enums/SortingOption"

const knowledgeBaseSelector = (state: IApplicationState) => state.knowledgeBaseState;

export const isOperationInProgressSelector = createSelector(knowledgeBaseSelector, knowledgeBaseState => knowledgeBaseState.isOperationInProgress);
export const filterTagsSelector =  createSelector(knowledgeBaseSelector, knowledgeBaseState => knowledgeBaseState.filterTags);

// main
const mainSelector = createSelector(knowledgeBaseSelector, knowledgeBaseState => knowledgeBaseState.main);
export const topicsSelector = createSelector(mainSelector,
    main => {
        if (main.isSortingByTopicName == SortingOption.Increase) {
            return main.topics.map((a) => a).sort((a, b) => a.name?.localeCompare(b.name))
        }
        else if (main.isSortingByTopicName == SortingOption.Decrease) {
            var res = main.topics.map((a) => a).sort((a, b) => b.name?.localeCompare(a.name))

            if (main.isCreatingTopic) {
                res = res.sort((a, b) => a.name === "" ? -1 : b.name === "" ? 1 : 0)
            }
            return res
        }
        else if (main.isSortingByTopicId == SortingOption.Increase) {
            return main.topics.map((a) => a).sort((a, b) =>  (a.topicId && b.topicId) ? a.topicId - b.topicId : 0)
        }
        else {
            return main.topics.map((a) => a).sort((a, b) => (a.topicId && b.topicId) ? b.topicId - a.topicId : 0)
        }
    }
);
export const topicByIdSelector = createSelector(mainSelector, main => (topicExternalId: string) =>
    main.topics.find(t => t.topicExternalId === topicExternalId) as ITopic
);
export const isCreatingTopicSelector = createSelector(mainSelector, main => main.isCreatingTopic);
export const isSortingByTopicIdSelector = createSelector(
    mainSelector,
    mainSelector => mainSelector.isSortingByTopicId
);
export const isSortingByTopicNameSelector = createSelector(
    mainSelector,
    mainSelector => mainSelector.isSortingByTopicName
);

// topic editor
const topicEditorSelector = createSelector(knowledgeBaseSelector, knowledgeBaseState => knowledgeBaseState.topicEditor);
export const selectedTopicSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.selectedTopic);
export const selectedTopicIdSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.selectedTopic.topicId);
export const editedTopicPairsSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.selectedTopic.topicPairs || []);
export const isCreatingTopicPairSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.isCreatingTopicPair);
export const isExportingPairsSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.isExportingPairs);
export const isPublishingQuestionsSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.isPublishingQuestions);
export const editedTopicPairByIdSelector = createSelector(editedTopicPairsSelector, editedTopicPairs => (editedTopicPairId: number) =>
    editedTopicPairs && editedTopicPairs.find(etp => etp.topicPairId === editedTopicPairId)
);
export const selectedTopicPairStatusSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.selectedTopicPairStatus);
export const selectedTopicParametersJsonSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.selectedTopic.parametersJson);

// transfer question popup
export const transferQuestionPopupDataSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.transferQuestionPopup.popupData);
export const isTransferQuestionPopupVisibleSelector = createSelector(
    topicEditorSelector,
    topicEditor => topicEditor.transferQuestionPopup.isTransferQuestionPopupVisible
);

// message box
export const isMessageBoxVisibleSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.messageBox.isMessageBoxVisible);
export const messageBoxDataSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.messageBox.data);  

// topic widget
const topicWidgetSelector = createSelector(knowledgeBaseSelector, knowledgetBaseState => knowledgetBaseState.topicEditor.topicWidget);
export const isTopicWidgetVisibleSelector = createSelector(topicWidgetSelector, topicWidget => topicWidget.isTopicWidgetVisible);
export const messagesSelector = createSelector(topicWidgetSelector, topicWidget =>
    topicWidget.messages.sort((m1, m2) => m1.createdDate.getTime() - m2.createdDate.getTime())
);
export const isGetAnswerInProgressSelector = createSelector(topicEditorSelector, topicEditor => topicEditor.topicWidget.isGetAnswerInProgress);


// topic item search
const topicPairSearchSelector = createSelector(knowledgeBaseSelector, knowledgetBaseState => knowledgetBaseState.topicPairsSearch);
export const topicSearchSelector = createSelector(topicPairSearchSelector, topicPairSearch => topicPairSearch.selectedTopic);
export const topicPairCategoriesSelector = createSelector(topicPairSearchSelector, topicPairSearch => topicPairSearch.topicPairCategories);
export const topicPairSourcesSelector = createSelector(topicPairSearchSelector, topicPairSearch => topicPairSearch.topicPairSources);

//topic questions
const topicQuestionSelector = createSelector(knowledgeBaseSelector, knowledgeBaseState => knowledgeBaseState.topicQuestion);
export const topicDefaultQuestionSelector = createSelector(topicQuestionSelector, topicQuestion => topicQuestion);