import { ISearchingTag, TagName, TagType } from "./../../../shared/components/tag-searching-input/~types/models/SearchingTag";
import i18n from "src/app/shared/localization/i18n";

export const TOPIC_PAIRS_ON_PAGE = 20;

export const getNlpSearchingTags = (topicPairCategories: string[], topicPairSources: string[]): ISearchingTag[] => [
    {
        id: 1,
        value: i18n.t("nlp.answer"),
        tagName: TagName.Custom,
        suggestions: ["3"],
        tagType: TagType.OpeningTag,
    },
    { 
        id: 2,
        value: i18n.t("nlp.subject"),
        suggestions: ["3", "4"],
        tagType: TagType.OpeningTag,
        tagName: TagName.Subject,
    },
    { 
        id: 3,
        value: "=",
        tagType: TagType.OperationTag,
    },
    {
        id: 4,
        tagName: TagName.Subject,
        value: "",
        suggestions: topicPairCategories,
        tagType: TagType.ClosingTag,
    },
    {
        id: 5,
        tagName: TagName.Source,
        value: "",
        suggestions: topicPairSources,
        tagType: TagType.ClosingTag,
    },
    
    { 
        id: 6,
        value: i18n.t("nlp.source"),
        suggestions: ["3", "5"],
        tagType: TagType.OpeningTag,
        tagName: TagName.Source,
    },
    { 
        id: 7,
        value: i18n.t("nlp.trigger"),
        tagName: TagName.Custom,
        suggestions: ["3"],
        tagType: TagType.OpeningTag,
    },
]