import * as React from "react";
import {
  IMessageItem,
  MessageType,
} from "../chats-panel/~store/models/MessageItem";
import { IMessagePreview } from "../chats-panel/~store/models/MessagePreview";
import MessageItem from "src/app/chats-panel/MessageItem/MessageItem";
import { OperatorStatusType } from "../chats-panel/~store/state/ChatItemsState";
import { GET_PICTURE_BY_ID_API_ROUTE } from "src/app/chats-panel/~api/apiRoutes";
import { IChatItem } from "../chats-panel/~store/models/ChatItem";
import { useEffect, useRef, useState } from "react";
import { animateScroll, Events } from "react-scroll";
import VisibilitySensor from "react-visibility-sensor";
import {
  IOperatorItem,
  OperatorType,
} from "../chats-panel/~store/models/OperatorItem";
import {
  HEIGHT_OF_OPERATORITEM_IN_REM,
  HEIGHT_OF_NO_OPERATORS_DIV_IN_REM,
  HEIGHT_OF_DROPDOWN_HEADER_IN_REM,
  HEIGHT_OF_LOADER_IN_REM,
  INPUT_LINES_COUNT,
  INPUT_SYMBOLS_COUNT,
} from "./ChatConstants";
import { NavLink, Link } from "react-router-dom";
import ChooseFileDialog, {
  FileType,
} from "src/app/shared/components/choose-file-dialog/ChooseFileDialog";
import PreviewFileDialog from "src/app/shared/components/preview-file-dialog/PreviewFileDialog";
import { showInfoToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import { ChannelType } from "src/app/contact/~store/models/ChannelType";
import MessageEditor from "./message-editor/MessageEditor";
import { getIsFileImage } from "src/app/shared/helpers/file-helper/FileFormatHelper";
import { PermissionType } from "src/app/shared/components/user-context/models/PermissionType";
import { ChatPanelRuleHelper } from "src/app/shared/helpers/rule-helper/ChatPanelRuleHelper";
import QuickAnswerDialog, {
  QuickAnswerDialogRef,
} from "./quick-answer-dialog/QuickAnswerDialog";
import { DELETED_MESSAGE_MESSAGE } from "./message-editor/message-editor-constants/MessageEditorConstants";
import TransferInviteDialog from "./transfer-invite-dialog/TransferInviteDialog";
import { Spinner } from "reactstrap";
import { ActionType } from "./transfer-invite-dialog/~types/TransferInviteDialogProps";
import { getDateTime } from "src/app/shared/helpers/date-helper/DateFormats";
import SilentChatButton from "./../shared/components/silent-chat-button/SilentChatButton";
import { TOGGLE_SILENT_CHAT_SUCCESS } from "./../chats-panel/~store/actions/~types";
import { IChatProps } from "./~types/ChatProps";
import history from "src/~store/history";
import {
  availableBotsTransferToSelector,
  availableContactTagsSelector,
  availableTiersSelector,
  canSendMessageSelector,
  channelSettingSelector,
  chatDraftSelector,
  chatHistoryContainerSelector,
  chatMessagePreviewSelector,
  contactAvatarIdSelector,
  contactNameSelector,
  contactTagsSelector,
  isContactOnlineSelector,
  isReceivingAvailibletiersSelector,
  isReceivingOnlineOperatorsSelector,
  isReceivingOnlineOperatorsToInviteSelector,
  isReceivingQuickAnswersSelector,
  messageToEditSelector,
  messegesSelector,
  onlineOperatorsSelector,
  onlineOperatorsToInviteSelector,
  operatorAvatarIdSelector,
  operatorChannelsSelector,
  operatorCurrentChatTextSelector,
  operatorMessageContentTypeSelector,
  operatorIdSelector,
  operatorNameSelector,
  operatorsStatusListSelector,
  operatorStatusSelector,
  permissionsSelector,
  quickAnswersSelector,
  selectedChatIdSelector,
  selectedChatSelector,
  updatingContactTagsSelector,
  pauseCategoriesSelector,
  sendingMessageSelector,
  sendingFilesSelector,
  isReceivingChatSelector,
} from "../chats-panel/~store/selectors";
import { IApplicationState } from "src/~store/models/ApplicationState";
import {
  chatsSelector,
  isMessageEditingSelector,
} from "../chats/~store/selector";
import {
  addChatHistory,
  addContactTags,
  changeOperatorChatText,
  changeOperatorMessageContentType,
  clearQuickAnswers,
  closeChat,
  deleteContactTags,
  editMessage,
  exportChatToZendesk,
  getAvailableBotsTransferTo,
  getAvailableTiers,
  getChannelOperators,
  getIsContactOnline,
  getOnlineOperatorsToInvite,
  getOnlineOperatorsToTransfer,
  getQuickAnswers,
  inviteOperatorsToChat,
  isChatSpam,
  joinChat,
  onOpenChat,
  onOperatorSendFileMessage,
  onReadMessages,
  pauseChat,
  removeOperatorFromChat,
  removeQuickAnswer,
  sendChatMessage,
  setChatVisibilityToState,
  setMessageToEdit,
  toggleMessageEditing,
  transferChat,
  transferChatToTier,
  unpauseChat,
} from "../chats-panel/~store/actions/actions";
import { AppThunkAction } from "src/~store/models/AppThunkAction";
import {
  ChangeOperatorChatTextAction,
  GetOnlineOperatorsToInviteAction,
  OnReadMessagesAction,
  UpdateContactTagsAction,
} from "../chats-panel/~store/actions/interfaces";
import { connect, useDispatch, useSelector } from "react-redux";
import "./Chat.css";
import { IChat } from "../chats/~store/models/Chat";
import { selectChat } from "../chats/~store/actions/actions";
import { OperatorPriorityType } from "../chats-panel/~store/models/ChannelItem";
import { Autocomplete, Checkbox, TextField } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Input from "@material-ui/core/Input";
import EmojiDialog from "./emoji-dialog/EmojiDialog";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";
import ContactIcon from "@mui/icons-material/AccountCircle";
import SpecifyChatCloseReasonDialog from "./specify-chat-close-reason-dialog/SpecifyChatCloseReasonDialog";
import ChooseMessageContentTypeDialog from "../shared/components/choose-message-content-type-dialog/ChooseMessageContentTypeDialog";
import { TopicPairContentType } from "../nlp/~store/models/enums/TopicPairContentType";
import PauseChatDialog from "./pause-chat-dialog/PauseChatDialog";
import { PAUSE_CATEGORIES } from "./pause-chat-dialog/~types/PauseChatProps";
import moment from "moment";
import Expire from "../shared/helpers/date-helper/expire/Expire";
import { useMutationObserver } from "../shared/helpers/custom-hooks/useMutationObserver";
import SpamChatButton from "../shared/components/spam-chat-button/SpamChatButton";
import SendingMessage from "../chats-panel/MessageItem/SendingMessage/SendingMessage";
import { ConvertTextToContent } from "../shared/components/text-to-content/TextToContent";
import Loader from "src/app/shared/components/loader/Loader";

type ReduxType = ReturnType<typeof mapStateToProps> & IChatProps;
let isVoiceRecording = false;
let opacityState = "hight";
let speechRecognizer: any;

const Chat = (props: ReduxType) => {
  const { t } = useTranslation();
  let lastAuthorName: string = "";

  const chatEl = useRef<HTMLDivElement>(null);
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const quickAnswerRef = useRef<QuickAnswerDialogRef>(null);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const [isSpecifyVisible, setIsSpecifyVisible] = useState(false);
  const [isScrollToUpVisible, setIsScrollToUpVisible] = useState(false);
  const [isScrollToBottomVisible, setIsScrollToBottomVisible] = useState(false);
  const [currentFileState, setCurrentFileState] = useState<{
    file: File | undefined;
    type: FileType | undefined;
  }>({ file: undefined, type: undefined });
  const [initialMessagesLoaded, setInitialMessagesLoaded] = useState(false);
  const [scrollReachedOnce, setScrollReachedOnce] = useState(false);

  const silentChatsId = useSelector(
    (state: IApplicationState) => state.chatItemsState.silentChatsId
  );
  const dispatch = useDispatch();

  const { webkitSpeechRecognition } = window as any;

  const channelSettingObj = props.channelSetting?.value
    ? JSON.parse(props.channelSetting.value)
    : undefined;

  const locale =
    "_" + (props.selectedChat?.messenger.contact.locale || "ru-RU");
  const nlpTopicName =
    channelSettingObj && channelSettingObj.NLP_TopicName
      ? channelSettingObj.NLP_TopicName + locale
      : "";
  const nlpAnswerTemplate =
    channelSettingObj && channelSettingObj.NLP_AnswerTemplate
      ? channelSettingObj.NLP_AnswerTemplate + locale
      : "";

  const showSpecifyChatCloseDialog =
    channelSettingObj && channelSettingObj.showSpecifyChatCloseDialog
      ? channelSettingObj.showSpecifyChatCloseDialog === "true"
      : false;

  const hasChatElScroll = (): boolean => {
    return chatEl.current
      ? chatEl.current.scrollHeight > chatEl.current.clientHeight
      : false;
  };

  const _addChatHistory = (direction: "up" | "down" | "both") => {
    if (direction !== "both") {
      if (direction === "up" && props.chatHistoryContainer.canGetMoreBefore) {
        props.addChatHistory(
          props.selectedChatId,
          props.chatHistoryContainer.gotBefore + 1,
          props.chatHistoryContainer.gotAfter,
          direction
        );
      }
      if (
        direction === "down" &&
        props.chatHistoryContainer.canGetMoreAfter &&
        props.selectedChat?.isClosed
      ) {
        props.addChatHistory(
          props.selectedChatId,
          props.chatHistoryContainer.gotBefore,
          props.chatHistoryContainer.gotAfter + 1,
          direction
        );
      }
    } else {
      if (
        props.chatHistoryContainer.canGetMoreBefore &&
        props.chatHistoryContainer.canGetMoreAfter
      ) {
        props.addChatHistory(
          props.selectedChatId,
          props.chatHistoryContainer.gotBefore + 1,
          props.selectedChat?.isClosed
            ? props.chatHistoryContainer.gotAfter + 1
            : props.chatHistoryContainer.gotAfter,
          direction
        );
      } else if (props.chatHistoryContainer.canGetMoreBefore) {
        props.addChatHistory(
          props.selectedChatId,
          props.chatHistoryContainer.gotBefore + 1,
          props.chatHistoryContainer.gotAfter,
          direction
        );
      } else if (
        props.chatHistoryContainer.canGetMoreAfter &&
        props.selectedChat?.isClosed
      ) {
        props.addChatHistory(
          props.selectedChatId,
          props.chatHistoryContainer.gotBefore,
          props.chatHistoryContainer.gotAfter + 1,
          direction
        );
      }
    }
  };

  const _onChatScroll = () => {    

    if (!hasChatElScroll()) {
      return;
    }

    if (!scrollReachedOnce) {
      setScrollReachedOnce(true);
      return;
    }
    _calculateSpaceToUpAndToBottom();
    if (chatEl.current?.scrollTop === 0) {
      //top

      _addChatHistory("up");
      chatEl.current.scroll(0, 1);
    }
    if (
      chatEl.current &&
      chatEl.current?.scrollHeight - chatEl.current?.scrollTop - 1 <=
        chatEl.current?.clientHeight
    ) {
      //bottom
      _addChatHistory("down");
      chatEl.current.scroll(0, chatEl.current?.scrollTop - 1);
    }
  };

  const _onTextOperatorChanged = (
    newText: string,
    isQuickAnswer: boolean = false
  ) => {
    if (props.selectedChatId === 0) return;
    props.changeOperatorChatText(newText);
    if (newText.length < 3) props.clearQuickAnswers();
    if (
      props.isReceivingQuickAnswers === false &&
      newText.length >= 3 &&
      !isQuickAnswer
    ) {
      props.getQuickAnswers(newText);
    }
  };

  const _onVoiceInputChangeText = (textFromSpeechRecognizer: string) => {
    if (props.selectedChatId === 0) return;
    props.changeOperatorChatText(textFromSpeechRecognizer);
  };

  const setMicrophoneOpacity = function () {
    let element = document.getElementById("material-icons mic-button");
    if (element) {
      if (opacityState == "low") {
        element.style.opacity = "1";
        opacityState = "hight";
      } else {
        element.style.opacity = "0.3";
        opacityState = "low";
      }
    }
  };

  const _onVoiceInputActivate = () => {
    if (!isVoiceRecording) {
      isVoiceRecording = true;
      const oldMessage = props.chatDraft;

      speechRecognizer = new webkitSpeechRecognition();
      speechRecognizer.interimResults = true;
      speechRecognizer.maxAlternatives = 1;
      speechRecognizer.lang = "ru-RU";

      let element = document.getElementById("material-icons mic-button");
      let textArea = document.getElementById("textarea");
      element?.setAttribute("class", "material-icons mic-button-recording");
      speechRecognizer.start();

      let id = setInterval(setMicrophoneOpacity, 1000);

      speechRecognizer.onresult = function (event: any) {
        if (textArea) {
          textArea.scrollTop = textArea.scrollHeight;
        }
        let viResult = event.results[event.resultIndex];
        _onVoiceInputChangeText(oldMessage + " " + viResult[0].transcript);
      };

      speechRecognizer.onaudioend = function () {
        element?.setAttribute("class", "material-icons mic-button");
        isVoiceRecording = false;
        clearInterval(id);
        if (element) element.style.opacity = "0.7";
      };
    } else {
      speechRecognizer.stop();
      isVoiceRecording = false;
    }
  };

  const _onJoinChat = (): void => {
    if (props.selectedChatId === 0) return;
    if (window.confirm(t("chats.askYouWannaJoinChat"))) {
      props.joinChat(props.selectedChatId);
    }
  };

  const _setChatVisibilityToAll = (value: boolean) => {
    props.setChatVisibility(value);
    props.setChatVisibilityToState(value);
  };

  const _onTransferChat = (redirectedTo: number) => {
    props.transferChat(props.operatorId, redirectedTo, props.selectedChatId);
    props.isChatSpam(props.selectedChatId);
    if (!props.permissions.includes(PermissionType.Chats_ManageOthersChats)) {
      _setChatVisibilityToAll(false);
    }
  };

  const _onLeaveChat = (): void => {
    if (props.selectedChatId === 0) return;
    if (window.confirm(t("chat.doLeaveChat"))) {
      props.removeOperatorFromChat(props.operatorId, props.selectedChatId);
    }
  };

  const _onInviteButtonClick = (operators: IOperatorItem[]) => {
    props.inviteOperatorsToChat(operators, props.selectedChatId);
  };

  const _removeOperator = (operatorIdToRemove: number, chatId: number) => {
    props.removeOperatorFromChat(operatorIdToRemove, chatId);
  };

  const _onOperatorSendMessage = () => {
    props.clearQuickAnswers();
    if (props.operatorChatText.length < 1 || props.selectedChatId === 0) return;
    props.sendChatMessage(
      props.selectedChatId,
      props.operatorChatText.trim(),
      props.operatorId,
      props.operatorMessageContentType
    );
    props.changeOperatorChatText("");
    props.changeOperatorMessageContentType(TopicPairContentType.Text);
    setTimeout(() => {
      _onScrollToBottomClicked();
    }, 100);
  };

  const _onScrollToUpClicked = () => {
    chatEl.current?.scrollTo(0, 1);
  };

  const _onScrollToBottomClicked = () => {
    chatEl.current?.scrollTo(
      0,
      chatEl.current?.scrollHeight - chatEl.current.clientHeight - 2
    );
  };

  const _onChatClosing = () => {
    if (showSpecifyChatCloseDialog) {
      setIsSpecifyVisible(true);
    } else {
      props.closeChat(props.selectedChatId, false);
    }
  };

  const _setMessageRead = (messageId: number) => {
    props.onReadMessages([messageId]);
  };

  const _onInputPasteHandler = (event: any): void => {
    for (const file of event.clipboardData.files) {
      if (getIsFileImage(file.type)) {
        setCurrentFileState({
          ...currentFileState,
          file,
          type: FileType.Image,
        });
      } else {
        setCurrentFileState({ ...currentFileState, file, type: FileType.File });
      }
    }
  };

  const _setAllMessagesRead = () => {
    const unreadMessages = props.messages.filter(
      (x) =>
        x.readTimestamp === null && x.messageType === MessageType.clientMessage
    );
    let readMessages: number[] = [];
    for (const um of unreadMessages) {
      readMessages.push(um.messageId);
    }
    if (readMessages.length > 0) {
      props.onReadMessages(readMessages);
    }
  };

  const _calculateSpaceToUpAndToBottom = () => {
    const MIN_HEIGHT = 50;
    if (chatEl.current) {
      if (
        chatEl.current.scrollHeight - chatEl.current.scrollTop >=
        chatEl.current.clientHeight + MIN_HEIGHT
      ) {
        setIsScrollToBottomVisible(true);
      } else {
        setIsScrollToBottomVisible(false);
      }
      if (chatEl.current.scrollTop >= MIN_HEIGHT) {
        setIsScrollToUpVisible(true);
      } else {
        setIsScrollToUpVisible(false);
      }
    }
  };

  const _focusOnMessageInput = () => {
    messageInputRef.current?.focus();
    const text = messageInputRef.current?.value;
    messageInputRef.current?.setSelectionRange(
      text?.length ?? 0,
      text?.length ?? 0
    );
  };

  const _getChatContent = () => {
    const userInStorage = sessionStorage.getItem("user");
    const customerId =
      userInStorage === null
        ? ""
        : JSON.parse(userInStorage.toString()).customerId;
    const contactAvatarUrl =
      props.contactAvatarId !== null
        ? GET_PICTURE_BY_ID_API_ROUTE +
          props.contactAvatarId +
          "&customerId=" +
          customerId
        : "";

    let operatorStatus = "";
    switch (props.operatorStatus) {
      case OperatorStatusType.Online:
        operatorStatus = "online-status";
        break;
      case OperatorStatusType.Offline:
        operatorStatus = "offline-status";
        break;
    }

    const _onContactTagsChanged = (
      e: React.SyntheticEvent<Element, Event>,
      value: string[]
    ) => {
      if (!props.updatingContactTags) {
        if (value.length > props.contactTags.length) {
          const addedTags = value.filter((v) => !props.contactTags.includes(v));
          props.addContactTags(addedTags);
        } else if (value.length < props.contactTags.length) {
          const removedTags = props.contactTags.filter(
            (v) => !value.includes(v)
          );
          props.deleteContactTags(removedTags);
        }
      }
    };

    const _onEmojiClick = (emojiObject: any) => {
      props.changeOperatorChatText(props.operatorChatText + emojiObject.native);
    };

    let chatName =
      props.selectedChat === undefined
        ? ""
        : t("common.chat") + " " + props.selectedChat.chatId?.toString();
    let chatCreated =
      props.selectedChat === undefined
        ? ""
        : getDateTime(new Date(props.selectedChat.timeCreated));
    let offsetY =
      (props.isReceivingOnlineOperators
        ? HEIGHT_OF_LOADER_IN_REM
        : props.onlineOperators.length > 0
        ? props.onlineOperators.length * HEIGHT_OF_OPERATORITEM_IN_REM
        : HEIGHT_OF_NO_OPERATORS_DIV_IN_REM) +
      HEIGHT_OF_DROPDOWN_HEADER_IN_REM +
      "rem";
    let dropdownMenyStyles = {
      position: "absolute" as "absolute",
      top: "3.4rem",
      left: 0,
      transform: `translateY(-${offsetY})`,
    };

    const _getTypingIndicatorDelay = () => {
      let timeout = 0;
      if (props.messagePreview)
        timeout =
          3000 -
          moment
            .duration(moment(new Date()).diff(props.messagePreview.lastUpdate))
            .asMilliseconds();
      return timeout > 0 ? timeout : 0;
    };

    const _onResentMessageClick = (message: IMessageItem) => {
      props.sendChatMessage(
        message.chatId,
        message.text,
        props.operatorId,
        message.contentType
      );
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    let contactConnectionStyle = "";
    if (props.isContactOnline) {
      contactConnectionStyle = " online";
    } else if (props.isContactOnline === false) {
      contactConnectionStyle = " offline";
    }

    return (
      <React.Fragment>
        <div className="hide-chat-panel-button">
          <img
            onClick={() => {
              _setChatVisibilityToAll(false);
            }}
          />
        </div>
        <table
          className="chat-container__opertator_menu"
          id="chat-container__opertator_menu"
        >
          {!props.isReceivingChat && (
            <tbody>
              <tr>
                <td rowSpan={2} className="contact-avatar-container">
                  {props.selectedChat && (
                    <NavLink
                      to={
                        "/contacts/edit-contact/" +
                        props.selectedChat.messenger.contactId
                      }
                    >
                      <Avatar
                        className={
                          "contact-item__contact-avatar" +
                          contactConnectionStyle
                        }
                        src={contactAvatarUrl}
                      >
                        <ContactIcon style={{ color: "#26a69a" }} />
                      </Avatar>
                    </NavLink>
                  )}
                </td>
                <td className="chat-name-container">
                  <Link to="/chats">{chatName}</Link>
                </td>
                <td>
                  <div className="silent-chat-wrap">
                    {!props.selectedChat?.isClosed && (
                      <SilentChatButton
                        selectedChatId={props.selectedChatId}
                        silentChatsId={silentChatsId}
                        onClick={() =>
                          dispatch({ type: TOGGLE_SILENT_CHAT_SUCCESS })
                        }
                      />
                    )}
                    <SpamChatButton />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="chat-header-channel-name">
                    {props.selectedChat?.channel.name}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="contact-name-container">
                  <span>{props.contactName}</span>
                </td>
                <td>
                  <div>{chatCreated}</div>
                </td>
              </tr>
              {false && (
                <tr>
                  <td rowSpan={1} colSpan={2} className="client-tags-container">
                    <div>
                      <Autocomplete
                        multiple
                        style={{ maxWidth: "40rem", maxHeight: "6rem" }}
                        size="small"
                        disableClearable
                        limitTags={3}
                        options={props.availableContactTags}
                        value={props.contactTags}
                        onChange={_onContactTagsChanged}
                        disableCloseOnSelect
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Теги"
                            size="medium"
                            type="button"
                          />
                        )}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          )}
          {props.isReceivingChat && <Loader />}
        </table>
        <div
          className="chat-container__scrollable"
          id="chat-container__scrollable"
        >
          <i
            className="material-icons chat-container__arrow-btn"
            onClick={() =>
              props.selectChat(false, props.chats, props.selectedChat)
            }
          >
            arrow_back_ios
          </i>
          <div
            ref={chatEl}
            id="chat-body"
            className="chat"
            onScroll={_onChatScroll}
          >
            <ul>
              {props.messages.map((item) => {
                return chatEl.current ? (
                  <VisibilitySensor
                    delayedCall={true}
                    key={item.messageId}
                    containment={chatEl.current}
                    onChange={(isVisible: boolean) => {
                      if (
                        isVisible &&
                        item.messageType === MessageType.clientMessage &&
                        item.readTimestamp === null
                      )
                        _setMessageRead(item.messageId);
                      _calculateSpaceToUpAndToBottom();
                      if (
                        isVisible &&
                        props.messages[props.messages.length - 1].messageId ===
                          item.messageId
                      ) {
                        _setAllMessagesRead();
                      }
                    }}
                  >
                    <li
                      key={item.messageId}
                      id={"message-" + item.messageId}
                      className={"chat-container__message-item-container"}
                    >
                      <MessageItem
                        messageItem={item}
                        authorName={_getAuthorName(item)}
                        onRemoveOperator={_removeOperator}
                        onMessageDidMount={() => {
                          if(item.chatId === props.selectedChatId && ((item.operatorId !== null && item.operatorId !== undefined) || 
                              (chatEl.current !== null && chatEl.current?.scrollHeight - chatEl.current?.scrollTop <= chatEl.current?.clientHeight * 2))) {
                                _onScrollToBottomClicked();
                          }}}
                        operatorId={props.operatorId}
                        chatOwnerId={props.selectedChat?.operatorId ?? 0}
                        nlpTopicName={nlpTopicName}
                        isSupervisor={props.permissions.includes(
                          PermissionType.Chats_ManageOthersChats
                        )}
                      />
                    </li>
                  </VisibilitySensor>
                ) : null;
              })}
              {props.sendingMessage &&
                props.selectedChat &&
                !props.selectedChat.isClosed && (
                  <SendingMessage
                    sendingMessage={props.sendingMessage}
                    onResendClick={_onResentMessageClick}
                  />
                )}
              {props.messagePreview && props.messagePreview.text.length > 0 && (
                <li className={"chat-container__message-item-container"}>
                  <div className="chat-container__message-preview message-item client-message-type">
                    <div className="message-preview__text">
                      {ConvertTextToContent(
                        TopicPairContentType.Text,
                        props.messagePreview.text,
                        false
                      )}
                    </div>
                    <Expire delay={_getTypingIndicatorDelay()}>
                      <div className="typing-indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </Expire>
                  </div>
                </li>
              )}
            </ul>
          </div>
          <i
            className="material-icons chat-container__arrow-btn"
            onClick={() =>
              props.selectChat(true, props.chats, props.selectedChat)
            }
          >
            arrow_forward_ios
          </i>
        </div>
        {props.sendingFiles.find(
          (item) => item.selectedChatId === props.selectedChatId
        ) && (
          <div className="chat-container__file_loader">
            Sending{" "}
            {
              props.sendingFiles.find(
                (item) => item.selectedChatId === props.selectedChatId
              )?.sendingFilesCount
            }{" "}
            file(s)... <Spinner style={{ width: "1rem", height: "1rem" }} />
          </div>
        )}
        {isScrollToUpVisible && (
          <div className="chat__scroll-to-up-container">
            <div
              className={"chat__scroll-to-up"}
              onClick={() => _onScrollToUpClicked()}
            ></div>
          </div>
        )}
        {isScrollToBottomVisible && (
          <div className="chat__scroll-to-bottom-container">
            <div
              className={"chat__scroll-to-bottom"}
              onClick={() => _onScrollToBottomClicked()}
            ></div>
          </div>
        )}
        {(showSpecifyChatCloseDialog && isSpecifyVisible) && (
          <SpecifyChatCloseReasonDialog
            chatId={props.selectedChatId}
            nlpTopicName={nlpTopicName}
            isVisible={isSpecifyVisible}
            setIsVisible={setIsSpecifyVisible}
            closeChat={props.closeChat}
          ></SpecifyChatCloseReasonDialog>
        )}
        <div className="chat-container__footer" id="chat-container__footer">
          <div
            className="chat-container__opertator_text-input-container"
            onKeyDown={(event) => {
              keyDownInChatFooter(event);
            }}
            onKeyUp={(event) => {
              keyUpInChatFooter(event);
            }}
          >
            <QuickAnswerDialog
              ref={quickAnswerRef}
              clearQuickAnswer={props.clearQuickAnswers}
              onQuickAnswerClick={(text: string) => {
                _onTextOperatorChanged(text, true);
                props.clearQuickAnswers();
                _focusOnMessageInput();
              }}
              quickAnswers={props.quickAnswers}
              removeQuickAnswer={props.removeQuickAnswer}
              onBlurInput={() => hiddenInputRef.current?.focus()}
              onFocusInput={() => messageInputRef.current?.focus()}
            />
            <table className="operator-text-input__table">
              <tbody>
                {ChatPanelRuleHelper.canWriteMessages(
                  props.selectedChat?.isClosed ?? true,
                  props.selectedChat?.operatorId ?? 0,
                  props.operatorId,
                  props.selectedChat?.invitedOperatorsId?.includes(
                    props.operatorId
                  ) ?? false,
                  props.operatorStatus
                ) && (
                  <tr className="operator-text-input_line">
                    <td className="message-params">
                      {props.selectedChat?.channel?.type != 8 && (
                        <ChooseFileDialog
                          onFileChoosen={(file, type) => {
                            setCurrentFileState({
                              ...currentFileState,
                              file,
                              type,
                            });
                          }}
                        />
                      )}
                      <PreviewFileDialog
                        file={currentFileState.file}
                        type={currentFileState.type}
                        onFileSend={() => {
                          if (
                            currentFileState.file !== undefined &&
                            currentFileState.type !== undefined
                          ) {
                            props.onOperatorSendFileMessage(
                              currentFileState.file,
                              currentFileState.type,
                              props.selectedChatId
                            );
                            setCurrentFileState({
                              file: undefined,
                              type: undefined,
                            });
                          }
                        }}
                        onFileCancel={() => {
                          if (
                            currentFileState.file !== undefined &&
                            currentFileState.type !== undefined
                          )
                            setCurrentFileState({
                              file: undefined,
                              type: undefined,
                            });
                        }}
                      ></PreviewFileDialog>
                    </td>
                    <td className="message-params">
                      <ChooseMessageContentTypeDialog
                        operatorMessageContentType={
                          props.operatorMessageContentType
                        }
                        onMessageContentTypeChoosen={
                          props.changeOperatorMessageContentType
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="button"
                        className="hidden"
                        ref={hiddenInputRef}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                      <Input
                        id="textarea"
                        className="operator-text-input"
                        multiline={true}
                        maxRows={INPUT_LINES_COUNT}
                        value={props.operatorChatText}
                        inputRef={messageInputRef}
                        disabled={
                          props.sendingMessage != null &&
                          !props.sendingMessage.failed
                        }
                        inputProps={{ maxLength: INPUT_SYMBOLS_COUNT }}
                        onFocus={(e) =>
                          e.currentTarget.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length
                          )
                        }
                        onPaste={_onInputPasteHandler}
                        onChange={(e) => {
                          _onTextOperatorChanged(e.target.value, false);
                        }}
                        spellCheck={true}
                        onKeyDown={(e) => {
                          if (!e.shiftKey && e.key === "Enter") {
                            const str = props.operatorChatText.replaceAll(
                              /\r?\n|\r|\s/g,
                              ""
                            );
                            if (str.length > 0) {
                              _onOperatorSendMessage();
                              setInitialMessagesLoaded(false);
                            }
                            e.preventDefault();
                          }
                        }}
                      />
                    </td>
                    {nlpAnswerTemplate.length > 0 && (
                      <td style={{ width: 35 }}>
                        <i
                          className="material-icons search-answer-template-button"
                          onClick={() => {
                            history.push(`/nlp/search/${nlpAnswerTemplate}`, {
                              theme: "",
                              time: new Date().getTime(),
                            });
                          }}
                        >
                          manage_search
                        </i>
                      </td>
                    )}
                    <td style={{ width: 35 }}>
                      <EmojiDialog onEmojiClick={_onEmojiClick}></EmojiDialog>
                    </td>
                    <td style={{ width: 35 }}>
                      {webkitSpeechRecognition ? (
                        <i
                          className="material-icons mic-button"
                          onClick={() => {
                            _onVoiceInputActivate();
                          }}
                          id="material-icons mic-button"
                        >
                          mic
                        </i>
                      ) : (
                        <img></img>
                      )}
                    </td>
                    <td style={{ width: 35 }}>
                      <img
                        className="send-button"
                        onClick={() => {
                          _onOperatorSendMessage();
                        }}
                      ></img>
                    </td>
                  </tr>
                )}
                {props.isEditingMessage && (
                  <tr>
                    <td>
                      <MessageEditor
                        messageToEdit={props.messageToEdit}
                        rowsMax={INPUT_LINES_COUNT}
                        onClose={props.toggleMessageEditing}
                        onEdit={(s) => {
                          setInitialMessagesLoaded(false);
                          props.editMessage(s);
                        }}
                        onChangeHeight={_setChatBodyHeight}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="chat-footer" id="chat-footer">
            {ChatPanelRuleHelper.canTransferChat(
              props.selectedChat?.isClosed ?? true,
              props.selectedChat?.operatorId ?? 0,
              props.operatorId,
              props.permissions.includes(PermissionType.Chats_ManageOthersChats)
            ) && (
              <TransferInviteDialog
                actionType={ActionType.Transfer}
                operatorsStatusList={props.operatorsStatusList}
                isReceivingOnlineOperators={props.isReceivingOnlineOperators}
                onlineOperators={props.onlineOperators}
                isReceivingAvailableTiers={props.isReceivingAvailable}
                availableTiers={props.availableTiers}
                availableBotsTransferTo={props.availableBotsTransferTo}
                onTransferChat={_onTransferChat}
                pauseCategories={props.pauseCategories}
                onTransferChatToTier={(tier) =>
                  props.transferChatToTier(tier, props.selectedChatId)
                }
                getOnlineOperators={() =>
                  props.getOnlineOperatorsToTransfer(props.selectedChatId)
                }
                channelId={props.selectedChat?.channelId ?? 0}
                onlineOperatorsToInvite={props.onlineOperatorsToInvite}
                getChannelOperators={props.getChannelOperators}
                isReceivingOnlineOperatorsToInvite={
                  props.isReceivingOnlineOperatorsToInvite
                }
                getOnlineOperatorsToInvite={() =>
                  props.getOnlineOperatorsToInvite(props.selectedChatId)
                }
                getAvailableTiers={() =>
                  props.getAvailableTiers(props.selectedChat?.channelId || 0)
                }
                getAvailableBotsTransferTo={() =>
                  props.getAvailableBotsTransferTo(
                    props.selectedChat?.channelId || 0
                  )
                }
                onInviteButtonClick={_onInviteButtonClick}
                onCloseDialog={() => {}}
              />
            )}
            {ChatPanelRuleHelper.canInviteOperator(
              props.selectedChat?.isClosed ?? true,
              props.selectedChat?.operatorId ?? 0,
              props.operatorId,
              props.permissions.includes(
                PermissionType.Chats_ManageOthersChats
              ),
              props.selectedChat?.operator?.type ?? OperatorType.Bot
            ) && (
              <TransferInviteDialog
                operatorsStatusList={props.operatorsStatusList}
                actionType={ActionType.Invite}
                isReceivingOnlineOperators={props.isReceivingOnlineOperators}
                onlineOperators={props.onlineOperators}
                isReceivingAvailableTiers={props.isReceivingAvailable}
                availableTiers={props.availableTiers}
                availableBotsTransferTo={props.availableBotsTransferTo}
                pauseCategories={props.pauseCategories}
                onTransferChat={_onTransferChat}
                onTransferChatToTier={(tier) =>
                  props.transferChatToTier(tier, props.selectedChatId)
                }
                getOnlineOperators={() =>
                  props.getOnlineOperatorsToTransfer(props.selectedChatId)
                }
                channelId={props.selectedChat?.channelId ?? 0}
                onlineOperatorsToInvite={props.onlineOperatorsToInvite}
                getChannelOperators={props.getChannelOperators}
                isReceivingOnlineOperatorsToInvite={
                  props.isReceivingOnlineOperatorsToInvite
                }
                getOnlineOperatorsToInvite={() =>
                  props.getOnlineOperatorsToInvite(props.selectedChatId)
                }
                getAvailableTiers={() =>
                  props.getAvailableTiers(props.selectedChat?.channelId || 0)
                }
                getAvailableBotsTransferTo={() =>
                  props.getAvailableBotsTransferTo(
                    props.selectedChat?.channelId || 0
                  )
                }
                onInviteButtonClick={_onInviteButtonClick}
                onCloseDialog={() => {}}
              />
            )}
            {ChatPanelRuleHelper.canJoinChat(
              props.selectedChat?.isClosed ?? true,
              props.selectedChat?.operatorId ?? 0,
              props.operatorId,
              props.permissions.includes(
                PermissionType.Chats_ManageOthersChats
              ),
              props.selectedChat?.invitedOperatorsId?.includes(
                props.operatorId
              ) ?? false,
              props.selectedChat !== undefined &&
                props.selectedChat.operator !== null
                ? props.selectedChat.operator.type
                : OperatorType.Bot
            ) && (
              <button onClick={_onJoinChat} className="chat-footer__close-chat">
                {t("chat.join")}
              </button>
            )}
            {ChatPanelRuleHelper.canLeaveChat(
              props.selectedChat?.operatorId ?? 0,
              props.operatorId,
              props.selectedChat?.invitedOperatorsId?.includes(
                props.operatorId
              ) ?? false,
              props.selectedChat?.isClosed ?? true
            ) && (
              <button
                onClick={_onLeaveChat}
                className="chat-footer__close-chat"
              >
                {t("chat.leave")}
              </button>
            )}
            {ChatPanelRuleHelper.canOpenChat(
              props.selectedChat?.isClosed ?? false
            ) && (
              <button
                onClick={() =>
                  props.onOpenChat(
                    props.selectedChat ?? ({} as IChatItem),
                    props.operatorId,
                    props.operatorStatus
                  )
                }
                className="chat-footer__close-chat"
              >
                {t("chat.continue")}{" "}
              </button>
            )}
            {ChatPanelRuleHelper.canCloseChat(
              props.selectedChat?.operatorId ?? 0,
              props.operatorId,
              props.selectedChat?.isClosed ?? false,
              props.permissions.includes(
                PermissionType.Chats_ManageOthersChats
              ),
              props.selectedChat?.onPause ?? false
            ) && (
              <button
                onClick={() => _onChatClosing()}
                className="chat-footer__close-chat"
              >
                {t("chat.close")}
              </button>
            )}
            <button
              className="chat__export-chat-btn"
              onClick={() => {
                if (window.confirm(t("chat.doExportChat"))) {
                  props.exportChatToZendesk(props.selectedChatId);
                }
              }}
            >
              {t("chat.export")}
            </button>
            {props.selectedChat &&
              ChatPanelRuleHelper.canPauseChat(
                props.selectedChat?.isClosed ?? true,
                props.selectedChat?.operatorId ?? 0,
                props.operatorId,
                props.permissions.includes(
                  PermissionType.Chats_ManageOthersChats
                )
              ) && (
                <PauseChatDialog
                  selectedChat={props.selectedChat}
                  pauseChat={props.pauseChat}
                  unpauseChat={props.unpauseChat}
                />
              )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const _setChatBodyHeight = () => {
    setTimeout(() => {
      let clientHeight = document.body.offsetHeight;
      let footerContainerHeight = document.getElementById(
        "chat-container__footer"
      )?.offsetHeight;
      let headerHeight = document.getElementById(
        "chat-container__opertator_menu"
      )?.offsetHeight;
      let editMessageInputHeight = document.getElementById(
        "message-editor__input"
      )?.offsetHeight;
      let footerButtonsHeight =
        document.getElementById("chat-footer")?.offsetHeight;
      document
        .getElementById("message-to-edit__text")
        ?.style.setProperty(
          "height",
          editMessageInputHeight != null
            ? `${editMessageInputHeight + 10}px`
            : ""
        );
      let chatBodyHeight =
        footerContainerHeight !== undefined && headerHeight !== undefined
          ? clientHeight - footerContainerHeight - headerHeight
          : 0;
      // chatBodyHeight -= footerButtonsHeight !== undefined ? footerButtonsHeight : 0;
      document
        .getElementById("chat-container__scrollable")
        ?.style.setProperty(
          "height",
          chatBodyHeight != 0 ? `${chatBodyHeight}px` : ""
        );
    });
  };

  const _getAuthorName = (message: IMessageItem): string | null => {
    if (message.messageType === MessageType.clientMessage) {
      if (lastAuthorName === props.contactName) return null;
      lastAuthorName = props.contactName;
      return props.contactName;
    }
    if (message.messageType === MessageType.operatorMessage) {
      if (message.operatorModel?.isDeleted == true) return "[Removed]";
      if (lastAuthorName === message.operatorName) return null;
      lastAuthorName = message.operatorName;
      return message.operatorName;
    }
    lastAuthorName = "";
    return null;
  };

  const getContactInfo = () => {
    const operatorAvatarUrl =
      props.contactAvatarId !== null
        ? GET_PICTURE_BY_ID_API_ROUTE + props.contactAvatarId
        : require("src/app/shared/images/Chat/contact_def.png");
    return (
      <div className="contact-info-container">
        <div className="contact-avatar">
          <img src={operatorAvatarUrl} alt="" />
        </div>
        <div className="contact-name">{props.contactName}</div>
      </div>
    );
  };

  const getLastOperatorMessage = (): IMessageItem => {
    return (
      [...props.messages]
        .reverse()
        .find(
          (value) =>
            value.messageType === MessageType.operatorMessage &&
            value.chatId === props.selectedChatId &&
            value.operatorId === props.operatorId &&
            !(value.isEdited && value.text === DELETED_MESSAGE_MESSAGE)
        ) ?? ({} as IMessageItem)
    );
  };

  const handleInitialScroll = () => {
    if (props.selectedChat?.isClosed) {
      // scroll to first message (initial chatId)
      const firstMessage = props?.messages?.find(
        (message) => props.selectedChatId === message.chatId
      );
      const firstMessageElement = document.getElementById(
        `message-${firstMessage?.messageId}`
      );
      firstMessageElement?.scrollIntoView(true);
    } else if (props.operatorId === props?.selectedChat?.operatorId) {
      // scroll to first unread message
      const firstUnreadMessageIndex = props.messages.findIndex(
        (message) =>
          message.readTimestamp === null &&
          message.messageType === MessageType.clientMessage
      );
      const firstUnreadMessage = props.messages[firstUnreadMessageIndex - 1];
      if (firstUnreadMessage) {
        const firstUnreadMessageElement = document.getElementById(
          `message-${firstUnreadMessage.messageId}`
        );
        firstUnreadMessageElement?.scrollIntoView(true);
        props.onReadMessages([firstUnreadMessage.messageId]);
      } else {
        _onScrollToBottomClicked();
      }
    } else {
      // scroll to bottom
      _onScrollToBottomClicked();
    }

    if (chatEl.current &&
       chatEl.current?.scrollHeight - chatEl.current?.scrollTop - chatEl.current?.clientHeight < 1) {
      chatEl.current.scrollTop = chatEl.current.scrollTop - 2;
    }

  };

  const keyUpInChatFooter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "ArrowUp") {
      if (!props.isEditingMessage) {
        let lastOpMessage = getLastOperatorMessage();

        if (
          lastOpMessage.messageId !== undefined &&
          props.operatorChatText.length === 0
        ) {
          if (
            props.selectedChat?.channelType !== ChannelType.Telegram &&
            props.selectedChat?.channelType !== ChannelType.Widget &&
            props.selectedChat?.channelType !== ChannelType.Vk
          ) {
            showInfoToastr(t("chat.cannotEditMessageWrongChannelType"));
            return;
          }

          props.setMessageToEdit(lastOpMessage);
          props.toggleMessageEditing();
        }
      }
    }
  };

  const keyDownInChatFooter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "ArrowUp") {
      if (props.quickAnswers.length > 0) {
        quickAnswerRef.current?.onUpArrow();
        return;
      }
    }
    if (event.key === "ArrowDown") {
      if (props.quickAnswers.length > 0) {
        quickAnswerRef.current?.onDownArrow();
        return;
      }
    }
    if (event.key === "Enter") {
      if (props.quickAnswers.length > 0) {
        quickAnswerRef.current?.onChooseQuickAmswer();
        return;
      }
    }
  };

  useEffect(() => {
    if (props.isEditingMessage) {
      props.toggleMessageEditing();
    }
    if (props.selectedChat?.isClosed === false) {
      _onTextOperatorChanged(props.chatDraft ?? "", false);
    }
    props.changeOperatorMessageContentType(TopicPairContentType.Text);
    props.getIsContactOnline(props.selectedChat?.messenger.externalId || "");
  }, [props.selectedChatId]);

  useEffect(() => {
    if (props.selectedChat?.isClosed === false) {
      _onTextOperatorChanged(props.chatDraft ?? "", false);
    }
  }, [props.selectedChat?.isClosed]);

  useEffect(() => {
    if (props.selectedChatId) {
      setInitialMessagesLoaded(false);
    }
  }, [props.selectedChatId]);

  useEffect(() => {
    if (
      initialMessagesLoaded ||
      !props.selectedChatId ||
      props.chatHistoryContainer.isReceivingHistory ||
      props.isReceivingChat
    ) {
      return;
    }

    if (
      !hasChatElScroll() &&
      (props.chatHistoryContainer.canGetMoreAfter ||
        props.chatHistoryContainer.canGetMoreBefore)
    ) {
      _addChatHistory("both");
      return;
    } else if (!initialMessagesLoaded && hasChatElScroll()) {      
      setInitialMessagesLoaded(true);
      handleInitialScroll();
    }
  }, [
    initialMessagesLoaded,
    props.selectedChatId,
    props.chatHistoryContainer.isReceivingHistory,
  ]);

  useMutationObserver(messageInputRef, () => {
    setTimeout(() => {
      _onScrollToBottomClicked();
    }, 10);
  });

  return (
    <div className={"chat-container"} id={"chat-container"}>
      {_getChatContent()}
    </div>
  );
};

const mapStateToProps = (state: IApplicationState, externalProps: any) => {
  return {
    chatDraft: chatDraftSelector(state),
    messages: messegesSelector(state),
    sendingMessage: sendingMessageSelector(state),
    messagePreview: chatMessagePreviewSelector(state),
    operatorChatText: operatorCurrentChatTextSelector(state),
    operatorMessageContentType: operatorMessageContentTypeSelector(state),
    operatorName: operatorNameSelector(state),
    canSendMessage: canSendMessageSelector(state),
    operatorStatus: operatorStatusSelector(state),
    operatorAvatarId: operatorAvatarIdSelector(state),
    contactAvatarId: contactAvatarIdSelector(state),
    contactName: contactNameSelector(state),
    contactTags: contactTagsSelector(state),
    selectedChat: selectedChatSelector(state),
    selectedChatId: selectedChatIdSelector(state),
    channelSetting: channelSettingSelector(state),
    operatorChannels: operatorChannelsSelector(state),
    isReceivingOnlineOperators: isReceivingOnlineOperatorsSelector(state),
    onlineOperators: onlineOperatorsSelector(state),
    availableBotsTransferTo: availableBotsTransferToSelector(state),
    onlineOperatorsToInvite: onlineOperatorsToInviteSelector(state),
    isReceivingOnlineOperatorsToInvite:
      isReceivingOnlineOperatorsToInviteSelector(state),
    isReceivingAvailable: isReceivingAvailibletiersSelector(state),
    availableTiers: availableTiersSelector(state),
    availableContactTags: availableContactTagsSelector(state),
    updatingContactTags: updatingContactTagsSelector(state),
    operatorId: operatorIdSelector(state),
    isEditingMessage: isMessageEditingSelector(state),
    messageToEdit: messageToEditSelector(state),
    isWideMode: externalProps.isWideMode,
    setChatVisibility: externalProps.setChatVisibility,
    permissions: permissionsSelector(state),
    quickAnswers: quickAnswersSelector(state),
    sendingFiles: sendingFilesSelector(state),
    operatorsStatusList: operatorsStatusListSelector(state),
    isReceivingQuickAnswers: isReceivingQuickAnswersSelector(state),
    chatHistoryContainer: chatHistoryContainerSelector(state),
    chats: chatsSelector(state),
    isContactOnline: isContactOnlineSelector(state),
    pauseCategories: pauseCategoriesSelector(state),
    isReceivingChat: isReceivingChatSelector(state),
  };
};

const mapDispatchToProps = (dispatch: any): IChatProps => {
  return {
    onOpenChat: (
      chat: IChatItem,
      operatorId: number,
      operatorStatus: OperatorStatusType
    ) => {
          return dispatch(onOpenChat(chat, operatorId, operatorStatus));
    },
    onReadMessages: (
      messagesId: number[]
    ): AppThunkAction<OnReadMessagesAction> => {
      return dispatch(onReadMessages(messagesId));
    },
    getOnlineOperatorsToInvite: (
      chatId: number
    ): AppThunkAction<GetOnlineOperatorsToInviteAction> => {
      return dispatch(getOnlineOperatorsToInvite(chatId));
    },
    getOnlineOperatorsToTransfer: (chatId: number) => {
      return dispatch(getOnlineOperatorsToTransfer(chatId));
    },
    getAvailableTiers: (channelId: number) => {
      return dispatch(getAvailableTiers(channelId));
    },
    getAvailableBotsTransferTo: (channelId: number) => {
      return dispatch(getAvailableBotsTransferTo(channelId));
    },
    onOperatorSendFileMessage: (file: File, type: FileType, chatId: number) => {
      return dispatch(onOperatorSendFileMessage(file, type, chatId));
    },
    toggleMessageEditing: () => {
      return dispatch(toggleMessageEditing());
    },
    setMessageToEdit: (messageToEdit: IMessageItem | undefined) => {
      return dispatch(setMessageToEdit(messageToEdit));
    },
    editMessage: (editedMessage: IMessageItem) => {
      return dispatch(editMessage(editedMessage));
    },
    addChatHistory: (
      chatIdStart: number,
      chatsIdUp: number,
      chatsIdDown: number,
      direction: "up" | "down" | "both"
    ) => {
      return dispatch(
        addChatHistory(chatIdStart, chatsIdUp, chatsIdDown, direction)
      );
    },
    clearQuickAnswers: () => {
      return dispatch(clearQuickAnswers());
    },
    removeQuickAnswer: (text: string) => {
      return dispatch(removeQuickAnswer(text));
    },
    exportChatToZendesk: (chatId: number) => {
      return dispatch(exportChatToZendesk(chatId));
    },
    getChannelOperators: (operatorsIds: number[], channelId: number) => {
      return getChannelOperators(operatorsIds, channelId);
    },
    inviteOperatorsToChat: (operators: IOperatorItem[], chatId: number) => {
      return inviteOperatorsToChat(operators, chatId);
    },
    transferChat: (
      redirectedBy: number,
      redirectedTo: number,
      chatId: number
    ) => {
      return dispatch(transferChat(redirectedBy, redirectedTo, chatId));
    },
    transferChatToTier: (tier: OperatorPriorityType, chatId: number) => {
      return transferChatToTier(tier, chatId);
    },
    changeOperatorChatText: (
      newText
    ): AppThunkAction<ChangeOperatorChatTextAction> => {
      return dispatch(changeOperatorChatText(newText));
    },
    changeOperatorMessageContentType: (contentType: TopicPairContentType) => {
      return dispatch(changeOperatorMessageContentType(contentType));
    },
    getQuickAnswers: (text: string) => {
      return dispatch(getQuickAnswers(text));
    },
    closeChat: (chatId: number, forcibly: boolean) => {
      return dispatch(closeChat(chatId, forcibly));
    },
    joinChat: (chatId) => {
      return dispatch(joinChat(chatId));
    },
    pauseChat: (chatId: number, pauseCategory: PAUSE_CATEGORIES) =>
      pauseChat(chatId, pauseCategory),
    unpauseChat: (chatId: number) => unpauseChat(chatId),
    setChatVisibilityToState: (value: boolean) => {
      return dispatch(setChatVisibilityToState(value));
    },
    removeOperatorFromChat: (operatorIdToRemove: number, chatId: number) => {
      return dispatch(removeOperatorFromChat(operatorIdToRemove, chatId));
    },
    sendChatMessage: (chatId, text, operatorId, contentType) => {
      return dispatch(sendChatMessage(chatId, text, operatorId, contentType));
    },
    selectChat: (
      isNext: boolean,
      chats: IChat[],
      selectedChatItem: IChatItem | undefined
    ) => dispatch(selectChat(isNext, chats, selectedChatItem)),
    addContactTags: (
      tags: string[]
    ): AppThunkAction<UpdateContactTagsAction> => {
      return dispatch(addContactTags(tags));
    },
    deleteContactTags: (
      tags: string[]
    ): AppThunkAction<UpdateContactTagsAction> => {
      return dispatch(deleteContactTags(tags));
    },
    getIsContactOnline: (messengerExternalId: string) =>
      dispatch(getIsContactOnline(messengerExternalId)),
    isChatSpam: (chatId: number) => dispatch(isChatSpam(chatId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
