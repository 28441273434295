import { IApplicationState } from "src/~store/models/ApplicationState";
import { createSelector } from 'reselect';

const chatItemsStateSelector = (state: IApplicationState) => state.chatItemsState;
const userContextSelector = (state: IApplicationState) => state.userContextState;

export const chatItemsSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.chatItems.sort((a, b) => 
        b.chatId - a.chatId
    )
);

export const chatMessagePreviewSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.chatMessagePreview.find(c => c.chatId == chatItems.selectedChatId)
);

export const isReceivingChatsSelector = createSelector(
    chatItemsStateSelector,
    state => state.chatItemsState,
    chatItemsState => chatItemsState.selectedChat
);

export const hubConnectionSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.hubConnection
);

export const operatorCurrentChatTextSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.operatorCurrentChatText
);

export const operatorMessageContentTypeSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.operatorCurrentMessageContentType
);

export const contactNameSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.contactName
);

export const contactTagsSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.contactTags
);

export const contactAvatarIdSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.contactAvatarId
);

export const channelSettingSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.channelSettings
);

export const operatorChannelsSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.operatorChannels
);

export const operatorNameSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.operatorName
);

export const operatorAvatarIdSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.operatorAvatarId
);

export const operatorIdSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.operatorId
);

export const isReceivingOnlineOperatorsSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.isReceivingOnlineOperators,
);

export const onlineOperatorsSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.onlineOperators,
);

export const isReceivingAvailibletiersSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.isReceivingAvailableTiers,
);

export const availableTiersSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.availableTiers,
);

export const availableBotsTransferToSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.botsTransferTo,
);

export const availableContactTagsSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.availableContactTags,
);

export const updatingContactTagsSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.updatingContactTags,
);

export const messegesSelector = createSelector (
    chatItemsStateSelector,
    chatItems => chatItems.messages.sort((a, b) => (new Date(a.timeSent).getTime()) - (new Date(b.timeSent).getTime()))
    );

export const sendingMessageSelector = createSelector (
    chatItemsStateSelector,
    chatItems => chatItems.sendingMessage
);

export const operatorStatusSelector = createSelector (
    chatItemsStateSelector,
    chatItems => chatItems.operatorStatus
);

export const pauseCategoriesSelector = createSelector (
    chatItemsStateSelector,
    chatItems => chatItems.pauseCategories
);

export const reconnectingStatusSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.reconnectingStatus
);

export const closeChatCategoriesSelector = createSelector (
    chatItemsStateSelector,
    chatItems => chatItems.closeChatCategories.map(c => { return { value: c, label: c } })
);

export const customChatTagsSettingSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.customChatTagsSetting
);

export const topicPairSubjectsSelector = createSelector (
    chatItemsStateSelector,
    chatItems => chatItems.closeChatSubjects.map(c => { return { value: c, label: c } })
);

export const isGettingTopicPairSubjectsSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.isGettingCloseChatSubjects
);

export const selectedChatIdSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.selectedChatId,
);

export const sendingFilesSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.sendingFiles,
);

export const selectedChatSelector = createSelector(
    chatItemsStateSelector,
    state => state.chatItemsState,
    chatItemsState => chatItemsState.selectedChat
);

export const isReceivingOnlineOperatorsToInviteSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.isReceivingOnlineOperatorsToInvite,
);

export const onlineOperatorsToInviteSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.onlineOperatorsToInvite,
);

export const isMessageEditingSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.isMessageEditing,
);

export const messageToEditSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.messageToEdit,
);

export const chatHistoryContainerSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.chatHistory,
);

export const permissionsSelector = createSelector(
    userContextSelector,
    userContext => userContext.permissions.permissionList,
);

export const quickAnswersSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.quickAnswersContainer.quickAnswers.sort((a, b) => 
        a.counter - b.counter
    )
);

export const isReceivingQuickAnswersSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.quickAnswersContainer.isReceivingQuickAnswers
);

export const canSendMessageSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.canSendMessage,
)

export const operatorsStatusListSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.operatorsStatusList,
)
export const chatDraftSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.chatsDrafts.get(chatItems.selectedChatId),
);

export const isContactOnlineSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.isContactOnline,
);

export const isReceivingChatSelector = createSelector(
    chatItemsStateSelector,
    chatItems => chatItems.isReceivingChat,
);
