import { IChatItemsState, ISendingFileContainer, OperatorStatusType } from "./state/ChatItemsState";
import { initState } from "./state/initChatItemsState";
import { Action, Reducer } from "redux";
import { MessageType, IMessageItem } from "./models/MessageItem";
import { startSignalRConnection } from "src/~signalR/SignalRHub";
import { notifyNewChat } from "./../../shared/helpers/natification-helper/NotificationHelper";

import { 
    GetOpenedOperatorChatsAction,
    GetOpenedOperatorChatsSuccessAction,
    StartSignalRAction,
    GetChatMessagesSuccessAction,
    UpdateIncomingChatInStateSuccessAction,
    UpdatePreviewMessageToStateAction,
    AddNewMessageToStateAction,
    ChangeOperatorChatTextSuccessAction,
    ChangeOperatorMessageContentTypeSuccessAction,
    ChangeOperatorStatusActionSuccessAction,
    GetOperatorInfoSuccessAction,
    GetChannelInfoSuccessAction,
    GetContactInfoSuccessAction,
    GetOperatorChannelsSuccessAction,
    IncreaseChatUnreadMessagesCountSuccessAction,
    OnReadMessagesSuccesAction,
    GetOnlineOperatorsSuccessAction,
    RemoveChatByChatIdAction,
    GetOnlineOperatorsToInviteSuccessAction,
    SetMessageToEditAction,
    SetChatVisibilityAction,
    AddChatsHistorySuccessAction,
    JoinChatSuccessAction,
    RemoveOperatorFromChatSuccess,
    getQuickAnswersSuccess,
    removeQuickAnswerSuccess,
    TransferChatSuccessAction,
    UpdateMessageInStateAction,
    updateChatsInChatPanelSuccess,
    GetOperatorStatusListSuccess,
    UpdateOperatorStatusInStateSuccess,
    ChangeReconnectingStatusAction,
    GetAvailableTiersSuccessAction,
    GetAvailableContactTagsSuccess,
    UpdateContactTagsSuccess,
    BotsAvailableTransferToSuccessAction,
    GetPauseCategoriesSuccess,
    GetOperatorDisconnectionTimeoutSuccess,
    GetCloseChatCategoriesSuccess,
    UpdateIsContactOnlineSuccess,
    UpdateSpamStatusSuccess,
    GetShowHintsForAllOperatorsSuccess,
    UpdateSendingMessageSuccessAction,
    OperatorSendFileMessageStartAction,
    GetTopicPairSubjectsByTopicNameActionSuccess,
    GetCustomChatTagsSettingsSuccess
} from "./actions/interfaces";

import { 
    GET_OPENED_OPERATOR_CHATS_SUCCESS,
    CHANGE_OPERTOR_STATUS_SUCCESS,
    START_SIGNALR_SUCCESS,
    STOP_SIGNALR_SUCCESS,
    UPDATE_INCOMING_CHAT_IN_STATE_SUCCESS,
    GET_CHAT_MESSEGES_START,
    GET_CHAT_MESSEGES_SUCCESS,
    UPDATE_MESSAGE_PREVIEW_IN_STATE_SUCCESS,
    ADD_NEW_MESSAGE_TO_STATE_SUCCESS,
    CHANGE_OPERATOR_CHAT_TEXT_SUCCESS,
    CHANGE_OPERATOR_MESSAGE_CONTENT_TYPE,
    GET_OPERATOR_INFO_SUCCESS,
    GET_CHANNEL_INFO_SUCCESS,
    GET_CONTACT_INFO_SUCCESS,
    GET_OPERATOR_CHANNELS_SUCCESS,
    INCREASE_CHAT_UNREAD_MESSAGES_COUNT,
    ON_READ_MESSAGES,
    GET_ONLINE_OPERATORS_START,
    GET_ONLINE_OPERATORS_SUCCESS,
    REMOVE_CHAT_BY_CHAT_ID,
    GET_ONLINE_OPERATORS_TO_INVITE_START,
    GET_ONLINE_OPERATORS_TO_INVITE_SUCCESS,
    GET_ONLINE_OPERATORS_TO_INVITE_ERROR,
    SET_MESSAGE_TO_EDIT,
    TOGGLE_MESSAGE_EDITING,
    SET_CHAT_VISIBILITY,
    ADD_CHAT_HISTORY_SUCCESS,
    ADD_CHAT_HISTORY_START,
    ADD_CHAT_HISTORY_ERROR,
    JOIN_CHAT_SUCCESS,
    JOIN_CHAT_START,
    REMOVE_OPERATOR_FROM_CHAT_SUCCESS,
    REMOVE_OPERATOR_FROM_CHAT_START,
    CLEAR_QUICK_ANSWERS_SUCCESS,
    GET_QUICK_ANSWERS_SUCCESS,
    GET_QUICK_ANSWERS_START,
    GET_QUICK_ANSWERS_ERROR,
    REMOVE_QUICK_ANSWER_SUCCESS,
    TRANSFER_CHAT_START,
    TRANSFER_CHAT_SUCCESS,
    UPDATE_MESSAGE_IN_STATE_SUCCESS,
    UPDATE_CHATS_IN_RIGHT_PANEL_SUCCESS,
    OPERATOR_SEND_FILE_MESSAGE_START,
    OPERATOR_SEND_FILE_MESSAGE_FINISH,
    GET_OPERATOR_STATUS_LIST_SUCCESS,
    UPDATE_OPERATOR_STATUS_IN_STATE_SUCCESS,
    CHANGE_RECONNECTING_STATUS_SUCCESS,
    TOGGLE_SILENT_CHAT_SUCCESS,
    GET_AVAILABLE_TIERS_START,
    GET_AVAILABLE_TIERS_SUCCESS,
    GET_AVAILABLE_TIERS_ERROR,
    CLOSE_CHAT_START,
    CLOSE_CHAT_SUCCESS,
    CLOSE_CHAT_ERROR,
    GET_AVAILABLE_CONTACT_TAGS_SUCCESS,
    UPDATE_CONTACT_TAGS_ERROR,
    UPDATE_CONTACT_TAGS_START,
    UPDATE_CONTACT_TAGS_SUCCESS,
    GET_AVAILABLE_BOTS_TRANSFER_TO_SUCCESS,
    GET_PAUSE_CATEGORIES_SUCCESS,
    GET_OPERATOR_DISCONNECTION_TIMEOUT_SUCCESS,
    GET_CHAT_CLOSE_CATEGORIES_SUCCESS,
    UPDATE_IS_CONTACT_ONLINE_SUCCESS,
    GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_SUCCESS,
    TOGGLE_SPAM_CHAT_SUCCESS,
    GET_SHOW_HINTS_FROM_ALL_OPERATORS_SUCCESS,
    UPDATE_SENDING_MESSAGE_SUCCESS,
    GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_START,
    GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_ERROR,
    GET_CUSTOM_CHAT_TAGS_SETTING_SUCCESS
} from "./actions/~types";
import { IFileItem } from "./models/FileItem";
import { HubConnectionState } from "@microsoft/signalr";
import { showErrorToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import { getTime } from "src/app/shared/helpers/date-helper/DateFormats";
import { IOperatorItem } from "./models/OperatorItem";
import { IMessagePreview } from "./models/MessagePreview";
import i18n from "src/app/shared/localization/i18n";
import { TopicPairContentType } from "src/app/nlp/~store/models/enums/TopicPairContentType";
import moment from "moment";
import { IChatItem } from "./models/ChatItem";

export type KnownActions = GetOpenedOperatorChatsAction;

export const chatItemsReducer: Reducer<IChatItemsState> = (
    state: IChatItemsState = initState,
    incommingAction: Action
): IChatItemsState => {
    const action = incommingAction as KnownActions;
    
    const hubConnection = state.hubConnection;

    switch(action.type)
    {
        case CHANGE_OPERATOR_CHAT_TEXT_SUCCESS:
            const newText = (action as ChangeOperatorChatTextSuccessAction).text;
            const {chatsDrafts} = state;

            chatsDrafts.set(state.selectedChatId, newText);
            
            return {
                ...state,
                operatorCurrentChatText: newText,
                chatsDrafts
            }

        case CHANGE_OPERATOR_MESSAGE_CONTENT_TYPE: {
            const messageContentType = (action as ChangeOperatorMessageContentTypeSuccessAction).messageContentType;

            return {
                ...state,
                operatorCurrentMessageContentType: messageContentType
            }
        }

        case ADD_NEW_MESSAGE_TO_STATE_SUCCESS: {
            const newMessage = (action as AddNewMessageToStateAction).message;
            let messages = state.messages;
            const index = state.messages.findIndex(m => m.messageId === newMessage.messageId);
            if(index > -1) {
                messages[index] = newMessage;
            }
            else {
                if(newMessage.chatId === state.selectedChatId){
                    messages = state.messages.concat([newMessage]);

                    if(newMessage.messageType === MessageType.clientMessage &&
                        state.chatItems.find(x => x.chatId === state.selectedChatId)?.operatorId === state.operatorId) {
                        hubConnection.invoke("ReceiveOrReadMessages", [newMessage.messageId], false );
                    }
                }
            }
            
            return {
                ...state,
                messages,
                sendingMessage: newMessage.operatorId === state.operatorId ? null : state.sendingMessage,
                chatItems: state.chatItems.map((c) => {return c})
            }
        }

        case UPDATE_MESSAGE_IN_STATE_SUCCESS: {
            const newMessage = (action as UpdateMessageInStateAction).message;
            const index = state.messages.findIndex(m => m.messageId === newMessage.messageId);
            if(index > -1) {
                state.messages[index] = newMessage;
            }
            return {
                ...state,
                messages: state.messages.map((c) => {return c})
            }
        }

        case ON_READ_MESSAGES: {
            const readMessagesId = (action as OnReadMessagesSuccesAction).payload.messagesId;
            const currUtcDateTime = new Date(new Date().toISOString());
            for(const mId of readMessagesId) {
                const index = state.messages.findIndex(x => x.messageId === mId);
                if(index > -1) {
                    state.messages[index].readTimestamp = currUtcDateTime;
                }
            }

            if(!isEmpty(readMessagesId) && 
            state.chatItems.find(x => x.chatId === state.selectedChatId)?.operatorId === state.operatorId) 
                hubConnection.invoke("ReceiveOrReadMessages", readMessagesId, true);

            return {
                ...state,
                messages: state.messages,
                chatItems: state.chatItems.map((c) => {return c})
            }
        }

        case GET_CHAT_MESSEGES_START: {
            return {
                ...state,
                isReceivingChat: true,
                messages: [],
                sendingMessage: null,
                selectedChat: undefined,
                chatHistory: {
                    canGetMoreAfter: false,
                    canGetMoreBefore: false,
                    gotAfter: 0,
                    gotBefore: 0,
                    isReceivingHistory: false,
                },
            };
        }

        case GET_CHAT_MESSEGES_SUCCESS: {
            let messages = (action as GetChatMessagesSuccessAction).payload.messages;
            const selectedChat = (action as GetChatMessagesSuccessAction).payload.chat;
            
            if(selectedChat) {
                if(selectedChat.fromHistory) {
                    const chatInfoMessage = createChatInfoMessage(selectedChat.chatId, selectedChat.timeCreated) 
                    messages = [chatInfoMessage].concat(messages);
                }
            }

            const currUtcDateTime = new Date(new Date().toISOString());

            let receivedMessagesId: number[] = [];

            for (const m in (action as GetChatMessagesSuccessAction).payload.messages) {
                if(messages[m].receivedTimestamp === null && messages[m].messageType === MessageType.clientMessage) {

                    receivedMessagesId.push(messages[m].messageId);
                    messages[m].receivedTimestamp = currUtcDateTime;
                }
            }

            if(receivedMessagesId.length > 0 &&
                state.chatItems.find(x => x.chatId === state.selectedChatId)?.operatorId === state.operatorId)
                hubConnection.invoke("ReceiveOrReadMessages", receivedMessagesId, false);

            /*if((action as GetChatMessagesSuccessAction).needToClearHistory) {
                const chatIndex = state.chatItems.findIndex(x => x.chatId === chatId);
                if(chatIndex > -1) {
                    state.chatItems[chatIndex].fromHistoryAndActive = false;
                }
            }*/

            return {
                ...state,
                chatItems: state.chatItems.filter(x => x.isClosed === false || x.fromHistory === true),
                messages: messages,
                selectedChatId: selectedChat.chatId,
                sendingMessage: null,
                selectedChat,
                chatHistory: {canGetMoreBefore: true, canGetMoreAfter: true, gotBefore: 0, gotAfter: 0, isReceivingHistory: false},
                isReceivingChat: false
            }
        }

        case UPDATE_SENDING_MESSAGE_SUCCESS: {
            const {sendingMessage} = (action as UpdateSendingMessageSuccessAction).payload;

            return {
                ...state,
                sendingMessage: sendingMessage
            }
        }

        case UPDATE_MESSAGE_PREVIEW_IN_STATE_SUCCESS: {
            const message = (action as UpdatePreviewMessageToStateAction).message;

            const now = moment(new Date());console.log(state.chatMessagePreview)

            const chatMessagePreview = state.chatMessagePreview
                .filter(c => c.chatId != message.chatId && moment.duration(now.diff(c.lastUpdate)).asMinutes() < 5)
                .concat([message]);

            return {
                ...state,
                chatMessagePreview
            }
        }

        case INCREASE_CHAT_UNREAD_MESSAGES_COUNT: {
            const chatId = (action as IncreaseChatUnreadMessagesCountSuccessAction).payload.chatId;
            const chat = state.chatItems.find(function(item) { return item.chatId === chatId });
            if(chat) {
                const index = state.chatItems.indexOf(chat);
                if(index > -1) {
                    state.chatItems.splice(index, 1);
                    const chats = state.chatItems.concat([chat]);
                    
                    return {
                        ...state,
                        chatItems: chats
                    }
                }
            }
            return {
                ...state
            }
        }

        case UPDATE_CHATS_IN_RIGHT_PANEL_SUCCESS: {
            const updatedChats = (action as updateChatsInChatPanelSuccess).payload.chats;
            const chatsInState = state.chatItems;

            updatedChats.forEach(chat => {
                const index = chatsInState.findIndex(c => c.chatId === chat.chatId);
                if(index > -1) {
                    if(chatsInState[index].operatorId === chat.operatorId || chat.invitedOperatorsId.includes(chatsInState[index].operatorId)) {
                        chatsInState[index].notAnsweredClientMessagesCount = chat.notAnsweredClientMessagesCount
                    }
                    else {
                        chatsInState.splice(index, 1);
                    }            
                }
            });
            return {
                ...state,
                chatItems: chatsInState.map((c) => {return c})
            }
        }

        case GET_CHANNEL_INFO_SUCCESS: {
            const {setting} = (action as GetChannelInfoSuccessAction).payload;
            return {
                ...state,
                channelSettings: setting
            }
        }
        
        case UPDATE_INCOMING_CHAT_IN_STATE_SUCCESS:
            let chats = state.chatItems;
            let selectedChat = state.selectedChat;

            let index = state.chatItems.findIndex(x => x.fromHistory === true);
            if(index > -1) {
                chats.splice(index, 1);
            }

            const newChat = (action as UpdateIncomingChatInStateSuccessAction).chat;
            if(newChat.operatorId === state.operatorId || newChat.invitedOperatorsId.includes(state.operatorId) || newChat.fromHistory) {
                index = state.chatItems.findIndex(x => x.chatId === newChat.chatId);
                if(index > -1) {
                    state.chatItems.splice(index, 1);
                    newChat.fromHistoryAndActive = newChat.fromHistory;
                    newChat.fromHistory = false;
                }
                chats = chats.concat([newChat]);
                notifyNewChat(newChat, state.selectedChat, state.operatorId);
            }
            else {
                chats = chats.filter(c => c.chatId != newChat.chatId);
            }

            if(state.selectedChatId === newChat.chatId)
                    selectedChat = newChat;
            return {
                ...state,
                chatItems: chats,
                selectedChat,
            }
            

        case GET_OPERATOR_INFO_SUCCESS: {
            const {name, avatarId, operatorId} = (action as GetOperatorInfoSuccessAction).payload;
            return {
                ...state,
                operatorAvatarId: avatarId,
                operatorName: name,
                operatorId,
            }
        }

        case GET_OPERATOR_CHANNELS_SUCCESS: {
            const channels = (action as GetOperatorChannelsSuccessAction).payload.channels; 
            return {
                ...state,
                operatorChannels: channels
            }
        }

        case GET_CONTACT_INFO_SUCCESS: {
            const {contactName, contactAvatarId, tags }= (action as GetContactInfoSuccessAction).payload;
            return {
                ...state,
                contactName,
                contactAvatarId,
                contactTags: tags
            }
        }

        case GET_OPENED_OPERATOR_CHATS_SUCCESS:
            let receivedChats = (action as GetOpenedOperatorChatsSuccessAction).payload.chats;
            
            for (const ch of receivedChats) {
                const index = state.chatItems.findIndex(x => x.chatId === ch.chatId);
                if(index > -1) {
                    state.chatItems.splice(index, 1);
                }
            }

            receivedChats = receivedChats.concat(state.chatItems);

            return {
                ...state,
                chatItems: receivedChats
            }
        case CHANGE_RECONNECTING_STATUS_SUCCESS:
            const newStatus = (action as ChangeReconnectingStatusAction).status;
            return {
                ...state,
                reconnectingStatus: newStatus
            }

        case GET_OPERATOR_DISCONNECTION_TIMEOUT_SUCCESS: {
            const operatorDisconnectionTimeout = (action as GetOperatorDisconnectionTimeoutSuccess).payload.operatorDisconnectionTimeout;
            return {
                ...state,
                operatorDisconnectionTimeout
            }
        }

        case CHANGE_OPERTOR_STATUS_SUCCESS:
            let {status, isFromUI} = (action as ChangeOperatorStatusActionSuccessAction);
            if(hubConnection.state === HubConnectionState.Connected){
                hubConnection.invoke("SetOperatorStatus", status);
            } else {
                if(isFromUI) {
                    showErrorToastr(i18n.t('error.connectionError'))
                }
                status = OperatorStatusType.Offline;
            }

            if(status === OperatorStatusType.Offline) {
                return {
                    ...state,
                    selectedChatId: 0,
                    selectedChat: undefined,
                    chatItems: [],
                    contactAvatarId: null,
                    contactName: "",
                    messages: [],
                    channelSettings: undefined,
                    operatorStatus: status
                }
            }
            return {
                ...state,
                operatorStatus: status
            }

        case GET_ONLINE_OPERATORS_START:
            return {
                ...state,
                isReceivingOnlineOperators: true,
            }

        case GET_ONLINE_OPERATORS_SUCCESS:
            const {onlineOperators} = (action as GetOnlineOperatorsSuccessAction).payload;
            
            return {
                ...state,
                onlineOperators,
                isReceivingOnlineOperators: false,
            }

        case GET_ONLINE_OPERATORS_TO_INVITE_START: {
            return {
                ...state,
                isReceivingOnlineOperatorsToInvite: true,
                onlineOperatorsToInvite: []
            }
        }

        case TOGGLE_SPAM_CHAT_SUCCESS: {
            const isSpam = (action as UpdateSpamStatusSuccess).payload.isSpam;
            if (state.selectedChat !== undefined) {
                return {
                    ...state,
                    selectedChat: {
                        ...state.selectedChat,
                        isSpam
                    }
                }
            }
        }

        case GET_ONLINE_OPERATORS_TO_INVITE_SUCCESS: {
            const {onlineOperatorsToInvite: onlineOperatorsToInvite} = (action as GetOnlineOperatorsToInviteSuccessAction).payload;

            return {
                ...state,
                isReceivingOnlineOperatorsToInvite: false,
                onlineOperatorsToInvite: onlineOperatorsToInvite?.filter(x => x.operatorId !== state.operatorId)
            }
        }

        case GET_ONLINE_OPERATORS_TO_INVITE_ERROR: {
            return {
                ...state,
                isReceivingOnlineOperatorsToInvite: false,
                onlineOperatorsToInvite: []
            }
        }

        case GET_AVAILABLE_TIERS_START: {
            return {
                ...state,
                isReceivingAvailableTiers: true,
                availableTiers: []
            }
        }

        case GET_AVAILABLE_TIERS_SUCCESS: {
            const tiers = (action as GetAvailableTiersSuccessAction).payload.tiers;
            return {
                ...state,
                isReceivingAvailableTiers: false,
                availableTiers: tiers
            }
        }

        case GET_AVAILABLE_TIERS_ERROR: {
            return {
                ...state,
                isReceivingAvailableTiers: false,
                availableTiers: []
            }
        }

        case GET_AVAILABLE_BOTS_TRANSFER_TO_SUCCESS: {
            const bots = (action as BotsAvailableTransferToSuccessAction).payload.bots;
            return {
                ...state,
                botsTransferTo: bots
            }
        }

        case SET_CHAT_VISIBILITY: {            
            const isChatVisible = (action as SetChatVisibilityAction).payload.value;

            return {
                ...state,
                selectedChatId:
                    isChatVisible === true ? state.selectedChatId : 0,
                selectedChat:
                    isChatVisible === true ? state.selectedChat : undefined,
                isChatVisible,
                sendingMessage: isChatVisible ? state.sendingMessage : null,
                chatHistory: {
                    canGetMoreAfter: false,
                    canGetMoreBefore: false,
                    gotAfter: 0,
                    gotBefore: 0,
                    isReceivingHistory: false,
                },
            };
        }

        case REMOVE_CHAT_BY_CHAT_ID: {
            const chatId = (action as RemoveChatByChatIdAction).payload.chatId;
            const index = state.chatItems.findIndex(c => c.chatId === chatId);
            if (index > -1) {
                state.chatItems[index].fromHistory = true;
            }
            return{
                ...state,
                chatItems: state.chatItems.map((c) => {return c})
            }
        }

        case ADD_CHAT_HISTORY_START: {
            return {
                ...state,
                chatHistory: {...state.chatHistory, isReceivingHistory: true}
            }
        }

        case ADD_CHAT_HISTORY_SUCCESS: {
            const chats = (action as AddChatsHistorySuccessAction).payload.chats;
            const direction = (action as AddChatsHistorySuccessAction).payload.direction;
            

            const chatHistoryContainer = {...state.chatHistory};
            const index = chats.findIndex(x => x.chatId === state.selectedChatId);

            if (index === -1) {
                return {
                ...state
                };
            }

            if(chatHistoryContainer.gotBefore >= index && direction === "up") {
                chatHistoryContainer.canGetMoreBefore = false;
            }
            if(chatHistoryContainer.gotAfter >= (chats.length - index) && direction === "down") {
                chatHistoryContainer.canGetMoreAfter = false;
            }
            if (
              direction === "both" &&
              (chatHistoryContainer.gotBefore >= index ||
                chatHistoryContainer.gotAfter >= chats.length - index)
            ) {
                chatHistoryContainer.canGetMoreAfter = false;
                chatHistoryContainer.canGetMoreBefore = false;
            }
            chatHistoryContainer.gotBefore = index;
            chatHistoryContainer.gotAfter = (chats.length - index - 1);
            chatHistoryContainer.isReceivingHistory = false;

            let messages: IMessageItem[] = [];

            for(const chat of chats) {
                const chatInfoMessage = createChatInfoMessage(chat.chatId, chat.timeCreated) 
                messages = messages.concat([chatInfoMessage]);
                messages = messages.concat(chat.chatMessages.sort((a, b) => a.messageId - b.messageId));
            }
            return{
                ...state,
                messages,
                chatHistory: chatHistoryContainer
            }
        }

        case ADD_CHAT_HISTORY_ERROR: {
            return {
                ...state,
                chatHistory: {...state.chatHistory, isReceivingHistory: false}
            }
        }

        case GET_QUICK_ANSWERS_START: {
            return {
                ...state,
                quickAnswersContainer: {...state.quickAnswersContainer, isReceivingQuickAnswers: true}
            }
        }

        case GET_QUICK_ANSWERS_SUCCESS: {
            const quickAnswers = (action as getQuickAnswersSuccess).payload.quickAnswers;

            return {
                ...state,
                quickAnswersContainer: {...state.quickAnswersContainer, isReceivingQuickAnswers: false, quickAnswers}
            }
        }

        case GET_QUICK_ANSWERS_ERROR: {
            return {
                ...state,
                quickAnswersContainer: {...state.quickAnswersContainer, isReceivingQuickAnswers: false}
            }
        }

        case CLEAR_QUICK_ANSWERS_SUCCESS: {
            return {
                ...state,
                quickAnswersContainer: {...state.quickAnswersContainer, quickAnswers: []}
            }
        }

        case REMOVE_QUICK_ANSWER_SUCCESS: {
            const quickAnswerText = (action as removeQuickAnswerSuccess).payload.quickAnswerText;
            
            return {
                ...state,
                quickAnswersContainer: {...state.quickAnswersContainer, quickAnswers: state.quickAnswersContainer.quickAnswers.filter(x => x.answerText !== quickAnswerText)}
            }
        }

        case START_SIGNALR_SUCCESS:
            hubConnection.on("PersonalMessage", (receivedMessage) => {
                (action as StartSignalRAction).callback(receivedMessage);
            });
            hubConnection.on("BroadcastMessage", (receivedMessage) => {
                (action as StartSignalRAction).callback(receivedMessage);
            });

            startSignalRConnection(hubConnection);

            return {
                ...state,
                hubConnection: hubConnection
            }
        
        case SET_MESSAGE_TO_EDIT: {
            const { messageToEdit } = (action as SetMessageToEditAction).payload;
            return {
                ...state,
                messageToEdit: messageToEdit ?? {} as IMessageItem
            }
        }
        
        case TOGGLE_MESSAGE_EDITING: {
            const isMessageEditing = !state.isMessageEditing;

            return {
                ...state,
                isMessageEditing
            }
        }

        case STOP_SIGNALR_SUCCESS:
                hubConnection.off("PersonalMessage");

                hubConnection.stop()
                    .then(() => {console.log('Connection stopped!')})
                    .catch(err => console.log('Error while stopping connection: ' + err));

                action.type = "";
                return chatItemsReducer(initState, action);

        case TRANSFER_CHAT_START:
        case JOIN_CHAT_START:
        case REMOVE_OPERATOR_FROM_CHAT_START:
            return {
                ...state,
                canSendMessage: false,
            }

        case TRANSFER_CHAT_SUCCESS: {
            return  {
                ...state,
                canSendMessage: true,   
            }
        }

        case JOIN_CHAT_SUCCESS:
            const selectedChatToJoin = (action as JoinChatSuccessAction).selectedChat;
            const chatItemsJoin = state.chatItems;
            for(const chat of chatItemsJoin) {
                if(chat.chatId === selectedChatToJoin?.chatId) {
                    chat.invitedOperatorsId.push(state.operatorId);
                    chat.fromHistory = false;
                }
            }

            return {
                ...state,
                chatItems: chatItemsJoin,
                canSendMessage: false,
            }

        case REMOVE_OPERATOR_FROM_CHAT_SUCCESS:
            return {
                ...state,
                chatItems: state.chatItems,
                canSendMessage: false,
            }

        case OPERATOR_SEND_FILE_MESSAGE_START: {
            let sendingFile = state.sendingFiles.find(item => item.selectedChatId === (action as OperatorSendFileMessageStartAction).payload.selectedChatId)
            if (sendingFile) {
                sendingFile.sendingFilesCount++;
                return {
                    ...state,
                    sendingFiles: state.sendingFiles.map(item => {
                        if (item.selectedChatId === sendingFile?.selectedChatId) {
                            return sendingFile
                        }
                        return item
                    })
                }
            }

            sendingFile = {
                sendingFilesCount: 1,
                selectedChatId: (action as OperatorSendFileMessageStartAction).payload.selectedChatId
            };

            return {
                ...state,
                sendingFiles: [...state.sendingFiles, sendingFile]
            }
        }

        case OPERATOR_SEND_FILE_MESSAGE_FINISH: {
            let sendingFile = state.sendingFiles.find(item => item.selectedChatId === (action as OperatorSendFileMessageStartAction).payload.selectedChatId)
            if (sendingFile && sendingFile.sendingFilesCount > 1) {
                sendingFile.sendingFilesCount--;
                return {
                    ...state,
                    sendingFiles: state.sendingFiles.map(item => {
                        if (item.selectedChatId === sendingFile?.selectedChatId) {
                            return sendingFile
                        }
                        return item
                    })
                }
            }

            return {
                ...state,
                sendingFiles: state.sendingFiles.filter(item => item.selectedChatId !== sendingFile?.selectedChatId)
            }
        }

            case GET_OPERATOR_STATUS_LIST_SUCCESS: {
                const {operatorsStatusList} = (action as GetOperatorStatusListSuccess).payload;

                return {
                    ...state,
                    operatorsStatusList
                }
            }

            case GET_AVAILABLE_CONTACT_TAGS_SUCCESS: {
                const {tags} = (action as GetAvailableContactTagsSuccess).payload;

                return {
                    ...state,
                    availableContactTags: tags
                }
            }

            case GET_PAUSE_CATEGORIES_SUCCESS: {
                const {pauseCategories} = (action as GetPauseCategoriesSuccess).payload;

                return {
                    ...state,
                    pauseCategories
                }
            }

            case GET_CHAT_CLOSE_CATEGORIES_SUCCESS: {
                const {closeChatCategories} = (action as GetCloseChatCategoriesSuccess).payload;

                return {
                    ...state,
                    closeChatCategories
                }
            }

            case GET_CUSTOM_CHAT_TAGS_SETTING_SUCCESS: {
                const { setting } = (action as GetCustomChatTagsSettingsSuccess).payload;

                return {
                    ...state,
                    customChatTagsSetting: setting
                }
            }

            case GET_SHOW_HINTS_FROM_ALL_OPERATORS_SUCCESS: {
                const {showHintsFromAllOperators} = (action as GetShowHintsForAllOperatorsSuccess).payload;

                return {
                    ...state,
                    showHintsFromAllOperators
                }
            }


            case GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_START: {
                return {
                    ...state,
                    isGettingCloseChatSubjects: true
                }
            }

            case GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_SUCCESS: {
            const { closeChatSubjects } = (action as GetTopicPairSubjectsByTopicNameActionSuccess).payload;

                return {
                    ...state,
                    closeChatSubjects,
                    isGettingCloseChatSubjects: false
                }
            }

            case GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_ERROR: {

                return {
                    ...state,
                    isGettingCloseChatSubjects: false
                }
            }

            case UPDATE_OPERATOR_STATUS_IN_STATE_SUCCESS: {
                const {operatorStatus} = (action as UpdateOperatorStatusInStateSuccess).payload;

                const index = state.operatorsStatusList.findIndex(o => o.operatorId === operatorStatus.operatorId);
                if(index > -1) {
                    state.operatorsStatusList.splice(index, 1);
                }

                return {
                    ...state,
                    operatorsStatusList: state.operatorsStatusList.concat([operatorStatus])
                }
            }

            case TOGGLE_SILENT_CHAT_SUCCESS: {
                var silentChatsId = state.silentChatsId.includes(state.selectedChatId) ? 
                    state.silentChatsId.filter(x => x != state.selectedChatId) : 
                    state.silentChatsId.concat([state.selectedChatId]);

                    sessionStorage.setItem("silentChats", JSON.stringify(silentChatsId))

                return {
                    ...state,
                    silentChatsId
                }
            }

            case CLOSE_CHAT_START: {
                return {
                    ...state,
                    isChatClosing: true
                }
            }

            case CLOSE_CHAT_SUCCESS:
            case CLOSE_CHAT_ERROR: {
                return {
                    ...state,
                    isChatClosing: false
                }
            }

            case UPDATE_CONTACT_TAGS_START: {
                return {
                    ...state,
                    updatingContactTags: true
                }
            }

            case UPDATE_CONTACT_TAGS_SUCCESS: {
                const contactTags = (action as UpdateContactTagsSuccess).payload.tags;

                return {
                    ...state,
                    contactTags,
                    updatingContactTags: false
                }
            }

            case UPDATE_CONTACT_TAGS_ERROR: {
                return {
                    ...state,
                    updatingContactTags: false
                }
            }

            case UPDATE_IS_CONTACT_ONLINE_SUCCESS: {
                const isContactOnline = (action as UpdateIsContactOnlineSuccess).payload.isContactOnline;

                return {
                    ...state,
                    isContactOnline: isContactOnline
                }
            }
            
        default:
            return state;
    }
};

const isEmpty = (obj: object) => {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

const createChatInfoMessage = (chatId: number, timeCreated: string): IMessageItem => {
    return  {
        messageId: -chatId, 
        chatId: chatId, 
        text: chatId + " - " + getTime(new Date(timeCreated)), 
        readTimestamp: new Date(), 
        receivedTimestamp: new Date(),
        messageType: MessageType.chatInfoMessage,
        timeSent: "",
        imageId: "0",
        operatorName: "",
        operatorModel: {} as IOperatorItem,
        isEdited: false,
        fileId: "",
        file: {} as IFileItem,
        contentType: TopicPairContentType.Text
    };
}