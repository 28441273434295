const moduleName: string = "CHAT/";

export const GET_OPENED_OPERATOR_CHATS_START: string = `${moduleName}GET_OPENED_OPERATOR_CHATS_START`;
export const GET_OPENED_OPERATOR_CHATS_SUCCESS: string = `${moduleName}GET_OPENED_OPERATOR_CHATS_SUCCESS`;
export const GET_OPENED_OPERATOR_CHATS_ERROR: string = `${moduleName}GET_OPENED_OPERATOR_CHATS_ERROR`;

export const ASSIGNMENT_CHATS_TO_OPERATOR_START: string = `${moduleName}ASSIGNMENT_CHATS_TO_OPERATOR_START`;
export const ASSIGNMENT_CHATS_TO_OPERATOR_SUCCESS: string = `${moduleName}ASSIGNMENT_CHATS_TO_OPERATOR_SUCCESS`;
export const ASSIGNMENT_CHATS_TO_OPERATOR_ERROR: string = `${moduleName}ASSIGNMENT_CHATS_TO_OPERATOR_ERROR`;

export const CHANGE_OPERTOR_STATUS_SUCCESS: string = `${moduleName}CHANGE_OPERTOR_STATUS_SUCCESS`;
export const CHANGE_RECONNECTING_STATUS_SUCCESS: string = `${moduleName}CHANGE_RECONNECTING_STATUS_SUCCESS`;

export const START_SIGNALR_SUCCESS: string = `${moduleName}START_SIGNALR_SUCCESS`;
export const STOP_SIGNALR_SUCCESS: string = `${moduleName}STOP_SIGNALR_SUCCESS`;

export const UPDATE_INCOMING_CHAT_IN_STATE_SUCCESS: string = `${moduleName}UPDATE_INCOMING_CHAT_IN_STATE_SUCCESS`;

export const ADD_NEW_MESSAGE_TO_STATE_SUCCESS: string = `${moduleName}ADD_NEW_MESSAGE_TO_STATE_SUCCESS`;

export const UPDATE_SENDING_MESSAGE_SUCCESS: string = `${moduleName}UPDATE_SENDING_MESSAGE_SUCCESS`;

export const UPDATE_MESSAGE_IN_STATE_SUCCESS: string = `${moduleName}UPDATE_MESSAGE_IN_STATE_SUCCESS`;

export const UPDATE_MESSAGE_PREVIEW_IN_STATE_SUCCESS: string = `${moduleName}UPDATE_MESSAGE_PREVIEW_IN_STATE_SUCCESS`;

export const GET_CHAT_MESSEGES_START: string = `${moduleName}GET_CHAT_MESSEGES_START`;
export const GET_CHAT_MESSEGES_SUCCESS: string = `${moduleName}GET_CHAT_MESSEGES_SUCCESS`;
export const GET_CHAT_MESSEGES_ERROR: string = `${moduleName}GET_CHAT_MESSEGES_ERROR`;

export const CHANGE_OPERATOR_CHAT_TEXT_SUCCESS: string = `${moduleName}CHANGE_OPERATOR_CHAT_TEXT_SUCCESS`;
export const CHANGE_OPERATOR_MESSAGE_CONTENT_TYPE: string = `${moduleName}CHANGE_OPERATOR_MESSAGE_CONTENT_TYPE`;

export const OPERATOR_SEND_FILE_MESSAGE_START: string = `${moduleName}OPERATOR_SEND_FILE_MESSAGE_START`;
export const OPERATOR_SEND_FILE_MESSAGE_FINISH: string = `${moduleName}OPERATOR_SEND_FILE_MESSAGE_FINISH`;

export const GET_OPERATOR_INFO_START: string = `${moduleName}GET_OPERATOR_INFO_START`;
export const GET_OPERATOR_INFO_SUCCESS: string = `${moduleName}GET_OPERATOR_INFO_SUCCESS`;
export const GET_OPERATOR_INFO_ERROR: string = `${moduleName}GET_OPERATOR_INFO_ERROR`;

export const GET_OPERATOR_CHANNELS_START: string = `${moduleName}GET_OPERATOR_CHANNELS_START`;
export const GET_OPERATOR_CHANNELS_SUCCESS: string = `${moduleName}GET_OPERATOR_CHANNELS_SUCCESS`;
export const GET_OPERATOR_CHANNELS_ERROR: string = `${moduleName}GET_OPERATOR_CHANNELS_ERROR`;

export const GET_OPERATOR_STATUS_LIST_SUCCESS: string = `${moduleName}GET_OPERATOR_STATUS_LIST_SUCCESS`;

export const GET_AVAILABLE_CONTACT_TAGS_SUCCESS: string = `${moduleName}GET_AVAILABLE_CONTACT_TAGS_SUCCESS`;

export const GET_PAUSE_CATEGORIES_SUCCESS: string = `${moduleName}GET_PAUSE_CATEGORIES_SUCCESS`;
export const GET_SHOW_HINTS_FROM_ALL_OPERATORS_SUCCESS: string = `${moduleName}GET_SHOW_HINTS_FROM_ALL_OPERATORS_SUCCESS`;
export const GET_OPERATOR_DISCONNECTION_TIMEOUT_SUCCESS: string = `${moduleName}GET_OPERATOR_DISCONNECTION_TIMEOUT_SUCCESS`;
export const GET_CHAT_CLOSE_CATEGORIES_SUCCESS: string = `${moduleName}GET_CHAT_CLOSE_CATEGORIES_SUCCESS`;
export const GET_CUSTOM_CHAT_TAGS_SETTING_SUCCESS: string = `${moduleName}GET_CUSTOM_CHAT_TAGS_SETTING_SUCCESS`;
export const GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_START: string = `${moduleName}GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_START`;
export const GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_SUCCESS: string = `${moduleName}GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_SUCCESS`;
export const GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_ERROR: string = `${moduleName}GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_ERROR`;

export const UPDATE_OPERATOR_STATUS_IN_STATE_SUCCESS: string = `${moduleName}UPDATE_OPERATOR_STATUS_IN_STATE_SUCCESS`;

export const GET_CHANNEL_INFO_START: string = `${moduleName}GET_CHANNEL_INFO_START`;
export const GET_CHANNEL_INFO_SUCCESS: string = `${moduleName}GET_CHANNEL_INFO_SUCCESS`;
export const GET_CHANNEL_INFO_ERROR: string = `${moduleName}GET_CHANNEL_INFO_ERROR`;

export const CLOSE_CHAT_START: string = `${moduleName}CLOSE_CHAT_START`;
export const CLOSE_CHAT_SUCCESS: string = `${moduleName}CLOSE_CHAT_SUCCESS`;
export const CLOSE_CHAT_ERROR: string = `${moduleName}CLOSE_CHAT_ERROR`;

export const GET_CONTACT_INFO_START: string = `${moduleName}GET_CONTACT_INFO_START`;
export const GET_CONTACT_INFO_SUCCESS: string = `${moduleName}GET_CONTACT_INFO_SUCCESS`;
export const GET_CONTACT_INFO_ERROR: string = `${moduleName}GET_CONTACT_INFO_ERROR`;

export const INCREASE_CHAT_UNREAD_MESSAGES_COUNT: string = `${moduleName}INCREASE_CHAT_UNREAD_MESSAGES_COUNT`;

export const ON_READ_MESSAGES: string = `${moduleName}ON_READ_MESSAGES`;

export const GET_ONLINE_OPERATORS_START: string = `${moduleName}GET_ONLINE_OPERATORS_START`;
export const GET_ONLINE_OPERATORS_SUCCESS: string = `${moduleName}GET_ONLINE_OPERATORS_SUCCESS`;
export const GET_ONLINE_OPERATORS_ERROR: string = `${moduleName}GET_ONLINE_OPERATORS_ERROR`;

export const GET_AVAILABLE_TIERS_START: string = `${moduleName}GET_AVAILABLE_TIERS_START`;
export const GET_AVAILABLE_TIERS_SUCCESS: string = `${moduleName}GET_AVAILABLE_TIERS_SUCCESS`;
export const GET_AVAILABLE_TIERS_ERROR: string = `${moduleName}GET_AVAILABLE_TIERS_ERROR`;

export const GET_AVAILABLE_BOTS_TRANSFER_TO_START: string = `${moduleName}GET_AVAILABLE_BOTS_TRANSFER_TO_START`;
export const GET_AVAILABLE_BOTS_TRANSFER_TO_SUCCESS: string = `${moduleName}GET_AVAILABLE_BOTS_TRANSFER_TO_SUCCESS`;
export const GET_AVAILABLE_BOTS_TRANSFER_TO_ERROR: string = `${moduleName}GET_AVAILABLE_BOTS_TRANSFER_TO_ERROR`;

export const GET_ONLINE_OPERATORS_TO_INVITE_START: string = `${moduleName}GET_ONLINE_OPERATORS_TO_INVITE_START`;
export const GET_ONLINE_OPERATORS_TO_INVITE_SUCCESS: string = `${moduleName}GET_ONLINE_OPERATORS_TO_INVITE_SUCCESS`;
export const GET_ONLINE_OPERATORS_TO_INVITE_ERROR: string = `${moduleName}GET_ONLINE_OPERATORS_TO_INVITE_ERROR`;

export const REMOVE_CHAT_BY_CHAT_ID: string = `${moduleName}REMOVE_CHAT_BY_CHAT_ID`;

// set message to edit
export const SET_MESSAGE_TO_EDIT: string = `${moduleName}SET_MESSAGE_TO_EDIT`;

// toggle message editing
export const TOGGLE_MESSAGE_EDITING: string = `${moduleName}TOGGLE_MESSAGE_EDITING`;

// edit message
export const EDIT_MESSAGE_START: string = `${moduleName}EDIT_MESSAGE_START`;
export const EDIT_MESSAGE_SUCCESS: string = `${moduleName}EDIT_MESSAGE_SUCCESS`;
export const EDIT_MESSAGE_ERROR: string = `${moduleName}EDIT_MESSAGE_ERROR`;

export const SET_CHAT_VISIBILITY: string = `${moduleName}SET_CHAT_VISIBILITY`;

export const ADD_CHAT_HISTORY_START: string = `${moduleName}ADD_CHAT_HISTORY_START`;
export const ADD_CHAT_HISTORY_SUCCESS: string = `${moduleName}ADD_CHAT_HISTORY_SUCCESS`;
export const ADD_CHAT_HISTORY_ERROR: string = `${moduleName}ADD_CHAT_HISTORY_ERROR`;

// join chat
export const JOIN_CHAT_START: string = `${moduleName}JOIN_CHAT_START`;
export const JOIN_CHAT_SUCCESS: string = `${moduleName}JOIN_CHAT_SUCCESS`;

// remove operator
export const REMOVE_OPERATOR_FROM_CHAT_START: string = `${moduleName}REMOVE_OPERATOR_FROM_CHAT_START`;
export const REMOVE_OPERATOR_FROM_CHAT_SUCCESS: string = `${moduleName}REMOVE_OPERATOR_FROM_CHAT_SUCCESS`;

// quick answers
export const GET_QUICK_ANSWERS_START: string = `${moduleName}GET_QUICK_ANSWERS_START`;
export const GET_QUICK_ANSWERS_SUCCESS: string = `${moduleName}GET_QUICK_ANSWERS_SUCCESS`;
export const GET_QUICK_ANSWERS_ERROR: string = `${moduleName}GET_QUICK_ANSWERS_ERROR`;

export const CLEAR_QUICK_ANSWERS_SUCCESS: string = `${moduleName}CLEAR_QUICK_ANSWERS_SUCCESS`;

export const REMOVE_QUICK_ANSWER_SUCCESS: string = `${moduleName}REMOVE_QUICK_ANSWER_SUCCESS`;

// export chat to zendesk
export const EXPORT_CHAT_TO_ZENDESK_SUCCESS: string = `${moduleName}EXPORT_CHAT_TO_ZENDESK_SUCCESS`;

// transfer chat
export const TRANSFER_CHAT_START: string = `${moduleName}TRANSFER_CHAT_START`;
export const TRANSFER_CHAT_SUCCESS: string = `${moduleName}TRANSFER_CHAT_SUCCESS`;

//update chats in chat panel
export const UPDATE_CHATS_IN_RIGHT_PANEL_SUCCESS: string = `${moduleName}UPDATE_CHATS_IN_RIGHT_PANEL_SUCCESS`;

export const TOGGLE_SILENT_CHAT_SUCCESS: string = `${moduleName}TOGGLE_SILENT_CHAT_SUCCESS`;
export const TOGGLE_SPAM_CHAT_SUCCESS: string = `${moduleName}TOGGLE_SPAM_CHAT_SUCCESS`;


export const UPDATE_CONTACT_TAGS_START: string = `${moduleName}UPDATE_CONTACT_TAGS_START`;
export const UPDATE_CONTACT_TAGS_SUCCESS: string = `${moduleName}UPDATE_CONTACT_TAGS_SUCCESS`;
export const UPDATE_CONTACT_TAGS_ERROR: string = `${moduleName}UPDATE_CONTACT_TAGS_ERROR`;

export const UPDATE_IS_CONTACT_ONLINE_SUCCESS: string = `${moduleName}UPDATE_IS_CONTACT_ONLINE_SUCCESS`;




















